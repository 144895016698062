import React, { useEffect } from 'react';
import { Typography } from 'antd';

import { createCdDrawer } from '../../../shared/components/cd-drawer/CdDrawer';
import { Enquiry } from '../types/enquiry';
import { ModalType } from '../../../shared/components/cd-modal-new/CdModal2';
import gettextCatalog from '../../../services/I18nService';
import { useEnquiriesPage } from '../hooks/use-enquiries-page';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { getFormattedDate } from '../../components/pop-overs/popover-helpers';

import { FormSubmissionViewer } from './FormSubmissionViewer';

export const showEnquiryDrawer = createCdDrawer<{ enquiry: Enquiry }>(
  ({ enquiry, setDrawerProps }) => {
    const { approveEnquiry, declineEnquiry } = useEnquiriesPage();

    const time = getFormattedDate({
      startDate: enquiry.startDate,
      endDate: enquiry.endDate,
      allDay: false,
    });

    useEffect(
      () =>
        setDrawerProps({
          modalType: ModalType.POPUP,
          okText: gettextCatalog.getString('Approve', null, 'Enquiry'),
          cancelText: gettextCatalog.getString('Decline', null, 'Enquiry'),
          onOk: () => approveEnquiry(enquiry),
          onCancel: () => declineEnquiry(enquiry),
        }),
      [approveEnquiry, declineEnquiry, enquiry, setDrawerProps]
    );

    return (
      <div>
        <Typography.Title style={{ marginTop: 0 }}>
          {gettextCatalog.getString('New enquiry for')}
        </Typography.Title>
        <CdVerticalSpace size={0}>
          <Typography.Text strong>{time.title}</Typography.Text>
          {time.subtitle && (
            <Typography.Text type="secondary">{time.subtitle}</Typography.Text>
          )}
        </CdVerticalSpace>
        <Typography.Title level={3}>
          {gettextCatalog.getString('Staff')}
        </Typography.Title>
        <Typography.Text>{enquiry.userIds.join(', ')}</Typography.Text>
        <FormSubmissionViewer submissionData={enquiry.submissionData} />
      </div>
    );
  }
);
