import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'apisauce';
import moment from 'moment';

import { CdClientSideTableApiSearchParam } from '../../../shared/components/cd-client-side-table/types';
import { handleError } from '../../../services/ErrorHandlingService';
import { Enquiry } from '../types/enquiry';
import cdApp from '../../../config';

export const useEnquiries = (params: CdClientSideTableApiSearchParam) =>
  useQuery({
    queryKey: ['useEnquiries', params],
    queryFn: async () => {
      // TODO: Implement
      const response = {
        ok: true,
        data: [
          {
            id: 1,
            startDate: moment().startOf('hour').toISOString(),
            endDate: moment().startOf('hour').add(1, 'hour').toISOString(),
            taxonomy: {
              id: 250952,
              name: 'Available for funeral',
            },
            userIds: [45787],
            submissionData: mockSubmission,
          },
          {
            id: 2,
            startDate: moment().startOf('hour').toISOString(),
            endDate: moment().startOf('hour').add(1, 'hour').toISOString(),
            taxonomy: {
              id: 251061,
              name: 'Available for funeral 4',
            },
            userIds: [1],
            submissionData: mockSubmission,
          },
        ],
        originalError: null,
        problem: null,
      } as ApiResponse<Enquiry[]>;
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }

      const filtered = (response?.data || [])
        .filter(
          (item) =>
            !params.extraData?.taxonomyIds?.length ||
            params.extraData?.taxonomyIds?.includes(item.taxonomy.id)
        )
        .filter(
          (item) =>
            !params.extraData?.personal || item.userIds.includes(cdApp.me.id)
        );

      return { items: filtered, total: filtered.length };
    },
  });

const mockSubmission = [
  {
    label: 'Mortician Contact',
    value: [
      {
        label: 'Organisation',
        value: 'Funeral Home Name',
      },
      {
        label: 'First name',
        value: 'Oscar',
      },
      {
        label: 'Surname',
        value: 'Perry',
      },
      {
        label: 'Work phone',
        value: '+44-8431-765-441',
      },
      {
        label: 'Email',
        value: 'oscarperry@funeralhome.uk',
      },
    ],
    isSection: true,
  },
  {
    label: 'Funeral details',
    value: [
      {
        label: 'Full name of the deceased',
        value: 'Ernest Watson',
      },
      {
        label: 'Type of funeral',
        value: 'Burial service',
      },
      {
        label: 'Service location',
        value: 'Cemetery',
      },
    ],
    isSection: true,
  },
  {
    label: 'Relative Contacts',
    value: [
      {
        label: 'Full name',
        value: 'Zach Watson',
      },
      {
        label: 'Phone number',
        value: '+44-2894-671-581',
      },
      {
        label: 'Additional notes for pastor',
        value: 'Please take care to...',
      },
    ],
    isSection: true,
  },
];
