import { Form, FormInstance } from 'antd';
import { useEffect, useState } from 'react';

export function useFormValidation(form?: FormInstance) {
  const watch = Form.useWatch([], form);
  const [{ isValid, errors }, setValid] = useState<{
    isValid: boolean;
    errors: {
      values?: Record<string, any>;
      errorFields?: { errors: string[]; name: string[]; warnings: string[] }[];
    };
  }>({ isValid: false, errors: {} });

  useEffect(() => {
    form
      ?.validateFields({ validateOnly: true })
      .then(() => setValid({ isValid: true, errors: {} }))
      .catch((errors) => setValid({ isValid: false, errors }));
  }, [form, watch]);

  return { isValid, errors };
}
