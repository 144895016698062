import { Form, Input, FormInstance } from 'antd';
import React, { useEffect } from 'react';

import { gettextCatalog } from '../services/I18nService';
import { CdSelect } from '../shared/components/cd-select/CdSelect';
import {
  emailFormat,
  phoneNumber,
  required,
} from '../shared/antd/validations/common';
import { Contact } from '../shared/models/people';
import cdApp from '../config';

import {
  ConflictingPersonWithRelation,
  ContactInformationType,
} from './services/people.service';
import { ConflictingPeopleRelationComponent } from './components/ConflictingPeopleRelationComponent';

import { CdPhone, CdPhoneInput } from '@/react/shared/components/CdPhoneInput';

export const SimplePeopleForm = (props: {
  person: Contact;
  createMode?: boolean;
  form: FormInstance;
  salutations?: any[];
  formalSalutations?: any[];
  setConflictingPeopleByEmail?: (
    conflictingPeople: ConflictingPersonWithRelation[]
  ) => void;
  setConflictingPeopleByPhone?: (
    conflictingPeople: ConflictingPersonWithRelation[]
  ) => void;
  showChurchSelector?: boolean;
  churches?: any[];
}) => {
  const email = Form.useWatch<string>('email', props.form);
  const phone = Form.useWatch<CdPhone>('phone', props.form);
  useEffect(() => {
    if (props.person) {
      props.form.setFieldsValue({
        phone: {
          short: cdApp.organization.countryIso2,
        },
        ...props.person,
        churches: props.person?.churches?.map((church) => church.id) || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.person]);
  return (
    <Form form={props.form} layout="vertical">
      {!props.createMode && (
        <>
          {props.salutations.length > 0 && (
            <Form.Item
              name="salutation"
              label={gettextCatalog.getString('Salutation')}
              rules={[{ type: 'string' }]}
            >
              <CdSelect placeholder="" options={props.salutations} />
            </Form.Item>
          )}
          {props.salutations.length > 0 && (
            <Form.Item
              name="formalSalutation"
              label={gettextCatalog.getString('Formal salutation')}
              rules={[{ type: 'string' }]}
            >
              <CdSelect placeholder="" options={props.formalSalutations} />
            </Form.Item>
          )}
          <Form.Item name="title" label={gettextCatalog.getString('Title')}>
            <Input placeholder={gettextCatalog.getString('e.g. Professor')} />
          </Form.Item>

          <Form.Item
            name="callName"
            label={gettextCatalog.getString('Call name')}
            rules={[{ type: 'string' }]}
          >
            <Input
              placeholder={gettextCatalog.getString(
                'e.g. Professor John Smith'
              )}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        name="firstName"
        label={gettextCatalog.getString('First name')}
        rules={[{ type: 'string' }]}
      >
        <Input placeholder={gettextCatalog.getString('e.g. John')} />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={gettextCatalog.getString('Last name')}
        rules={[{ type: 'string' }]}
      >
        <Input placeholder={gettextCatalog.getString('e.g. Smith')} />
      </Form.Item>
      {props.createMode && (
        <>
          <Form.Item
            name="email"
            label={gettextCatalog.getString('E-mail address')}
            rules={[emailFormat]}
          >
            <Input
              placeholder={gettextCatalog.getString(
                'e.g. john.smith@example.com'
              )}
            />
          </Form.Item>
          <div style={{ marginBottom: 16 }}>
            <ConflictingPeopleRelationComponent
              emailValue={email}
              phoneValue={phone?.phone && `+${phone.code + phone.phone}`}
              valueType={ContactInformationType.EMAIL}
              shouldListSingleConflictingPeople={false}
              setSelectedValues={props.setConflictingPeopleByEmail}
            />
          </div>
          <Form.Item
            name="phone"
            label={gettextCatalog.getString('Phone')}
            rules={[phoneNumber]}
            initialValue={{ short: cdApp.organization.countryIso2 }}
          >
            <CdPhoneInput />
          </Form.Item>
          <div style={{ marginBottom: 16 }}>
            <ConflictingPeopleRelationComponent
              emailValue={email}
              phoneValue={phone?.phone && `+${phone.code + phone.phone}`}
              valueType={ContactInformationType.PHONE}
              shouldListSingleConflictingPeople={true}
              setSelectedValues={props.setConflictingPeopleByPhone}
            />
          </div>
        </>
      )}
      {props.showChurchSelector && (
        <Form.Item
          name="churches"
          label={gettextCatalog.getString('Parish')}
          rules={[required(gettextCatalog.getString('Parish is required.'))]}
          extra={gettextCatalog.getString(
            'Only users from selected parishes will have access to the contact.'
          )}
        >
          <CdSelect
            mode="multiple"
            options={props.churches?.map((church) => ({
              value: church.id,
              label: church.name,
            }))}
            placeholder={gettextCatalog.getString(
              'Choose one or more parishes...'
            )}
          />
        </Form.Item>
      )}
    </Form>
  );
};
