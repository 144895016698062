import React, { useEffect } from 'react';
import { Form } from 'antd';

import { useEditContactProfileInformation } from '../../hooks/useContactProfileInformation';

import { EditPersonalInformationForm } from './EditPersonalInformationForm';

import {
  ModalType,
  createCdModal,
} from '@/react/shared/components/cd-modal-new/CdModal2';

export const showEditPersonalInformationModal = createCdModal<{
  title: string;
  contactId: number;
  personFields: any;
  personRelations: any;
}>(({ setModalProps, title, contactId, personFields, personRelations }) => {
  const [contactProfileInformationForm] = Form.useForm();
  const {
    saveContactProfileInfo,
    initialValues,
    isPending,
    isRefetching,
    setConflictingPeopleByEmail,
    setConflictingPeopleByPhone,
    conflictingPeopleByEmail,
    conflictingPeopleByPhone,
    civilStatusOptions,
    allergyOptions,
    dietaryOptions,
    denominationOptions,
  } = useEditContactProfileInformation(contactId, personFields);
  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: title,
      width: 900,
      maxBodyHeight: 80,
      onOk: async () =>
        await saveContactProfileInfo({ form: contactProfileInformationForm }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModalProps]);

  return (
    <EditPersonalInformationForm
      contactId={contactId}
      form={contactProfileInformationForm}
      accessFields={personFields}
      personRelations={personRelations}
      initialValues={initialValues}
      isPending={isPending}
      isRefetching={isRefetching}
      setConflictingPeopleByEmail={setConflictingPeopleByEmail}
      setConflictingPeopleByPhone={setConflictingPeopleByPhone}
      conflictingPeopleByEmail={conflictingPeopleByEmail}
      conflictingPeopleByPhone={conflictingPeopleByPhone}
      civilStatusOptions={civilStatusOptions}
      allergyOptions={allergyOptions}
      dietaryOptions={dietaryOptions}
      denominationOptions={denominationOptions}
    />
  );
});
