import { Form } from 'antd';
import React from 'react';

import gettextCatalog from '../../../services/I18nService';
import CdClientSideTable from '../../../shared/components/cd-client-side-table/CdClientSideTable';
import { CdDropdown } from '../../../shared/components/cd-dropdown/CdDropdown';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import {
  CdEyeIcon,
  CdCheckIcon,
  CdBan,
} from '../../../shared/components/Icons';
import { useEnquiries } from '../hooks/use-enquiries';
import { useEnquiriesPage } from '../hooks/use-enquiries-page';
import { DisplayDateSimple } from '../../../shared/components/FormattedDate';

export const OpenEnquiriesTab = () => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const { viewEnquiry, approveEnquiry, declineEnquiry } = useEnquiriesPage();

  return (
    <Form form={form} initialValues={{ personal: true }}>
      <CdVerticalSpace size="middle">
        {/* OMITTED IN LAUNCH VERSION <Space>
          <Form.Item name="personal" noStyle>
            <Radio.Group
              options={[
                {
                  label: gettextCatalog.getString(
                    'My',
                    undefined,
                    'Next to a radio button, which shows my forms'
                  ),
                  value: true,
                },
                {
                  label: gettextCatalog.getString(
                    'All',
                    undefined,
                    'Next to a radio button, which shows all forms'
                  ),
                  value: false,
                },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          <Form.Item name="taxonomyIds" noStyle>
            <CdEventCategorySelect
              type={CategoryType.availability}
              placeholder={gettextCatalog.getString('Type')}
              style={{ width: 300 }}
              mode="multiple"
              maxTagCount="responsive"
            />
          </Form.Item>
        </Space>*/}
        <CdClientSideTable
          querySource={useEnquiries}
          searchParams={values}
          noStyle
          columns={[
            {
              title: gettextCatalog.getString('Date'),
              dataIndex: 'startDate',
              width: 300,
              render: (date) => (
                <DisplayDateSimple date={date} allDay={false} />
              ),
            },
            {
              title: gettextCatalog.getString('Type'),
              dataIndex: 'taxonomy',
              width: 200,
              render: (taxonomy) => taxonomy.name,
            },
            {
              title: gettextCatalog.getString('Details'),
              key: 'details',
              render: (_, record) => <>{record.userIds.join(', ')}</>,
            },
            {
              key: 'actions',
              width: 1,
              render: (_, record) => (
                <CdDropdown
                  items={[
                    {
                      key: 'view',
                      label: gettextCatalog.getString('View'),
                      icon: <CdEyeIcon />,
                      onClick: () => viewEnquiry(record),
                    },
                    {
                      type: 'divider',
                    },
                    {
                      key: 'approve',
                      label: gettextCatalog.getString(
                        'Approve',
                        null,
                        'Enquiry'
                      ),
                      icon: <CdCheckIcon />,
                      onClick: () => approveEnquiry(record),
                    },
                    {
                      key: 'decline',
                      label: gettextCatalog.getString(
                        'Decline',
                        null,
                        'Enquiry'
                      ),
                      icon: <CdBan />,
                      onClick: () => declineEnquiry(record),
                    },
                  ]}
                  size="small"
                >
                  {gettextCatalog.getString('Actions')}
                </CdDropdown>
              ),
            },
          ]}
          emptyStateProps={{
            title: gettextCatalog.getString('No open enquiries'),
          }}
        />
      </CdVerticalSpace>
    </Form>
  );
};
