import { DateSelectArg } from '@fullcalendar/react';
import { Popover } from 'antd';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useEventListener } from 'usehooks-ts';

import { dateSelectArgToEventData } from '../../helpers/create-event';
import { DefaultCalendarPopoverProps } from '../CalendarEventPopover';
import CalendarViewService from '../../services/CalendarViewService';

import { CreateEventPopover } from './CreateEventPopover';

export const CreateEventPopoverHandler = forwardRef((_, ref) => {
  const [state, setState] = useState<DateSelectArg | null>(null);
  const [target, setTarget] = useState<Element | null>(null);
  const internalRef = useRef<Element>();

  // Function passed to the ref. Used instead of useState on the Calendar component to avoid re-renders.
  useImperativeHandle(ref, () => ({
    onSelect: (props: DateSelectArg) => {
      // Find target before setState to avoid the date changing in the popover before it moves new target.
      const dayViewElement = document.getElementById('cd_event_item_new');
      if (dayViewElement) {
        setTarget(dayViewElement);
      } else {
        const allDayElement =
          document.getElementsByClassName('fc-highlight')[0];
        if (allDayElement) {
          setTarget(allDayElement);
        }
      }
      setState(props);
    },
  }));

  const closePopover = () => {
    setTarget(null);
    CalendarViewService.unselect();
  };

  // Close the popup when pressing Escape on the keyboard. The calendar itself doesn't send an event, so we need to handle it.
  useEventListener(
    'keydown',
    (event) => event.key === 'Escape' && closePopover()
  );

  // Close the popup when clicking outside. This is needed because otherwise FullCalendar keeps the event open, because the brown highlight is still active.
  useEventListener('mousedown', (event) => {
    if (
      internalRef.current &&
      !internalRef.current.contains?.(event.target as any)
    ) {
      closePopover();
    }
  });

  // Close the popover when you click on another event. Events don't propagate the event to the parent, so we need to close the popover manually.
  useEventListener('message', (event) => {
    if (
      event.isTrusted &&
      event.data.action === 'cd-close-create-event-popover'
    )
      setTarget(null);
  });

  const event = dateSelectArgToEventData(state);

  return target
    ? createPortal(
        <Popover
          {...DefaultCalendarPopoverProps}
          arrow={true}
          align={{
            // Custom align settings so the offset is fixed to child, so the popover doesn't jump when changing calendar type
            points: ['tr', 'tl'],
            offset: [-10, -60],
            // Arrow is always targeted at center of target
            targetOffset: [0, 8],
            overflow: { adjustX: true, adjustY: true },
          }}
          content={
            <CreateEventPopover
              event={{
                ...event,
                resources: event.resources?.map(
                  (resourceId) => `resource-${resourceId}`
                ),
              }}
              closePopover={closePopover}
            />
          }
          open={true}
          ref={internalRef}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
            }}
          />
        </Popover>,
        target
      )
    : null;
});
CreateEventPopoverHandler.displayName = 'CreateEventPopoverHandler';
