import React from 'react';
import { Card, Col, Divider, Radio, Row, Space, Typography } from 'antd';
import moment from 'moment';
import { every, isNull } from 'lodash';

import { ContactType } from '../types/Types';

import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';
import gettextCatalog from '@/react/services/I18nService';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { formatAddress } from '@/react/shared/utils';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { CdOpenInNewWindow } from '@/react/shared/components/Icons';

interface ContactDetailsProps {
  contact: ContactType;
}

export const ContactDetails = (props: ContactDetailsProps) => {
  const { contact } = props;

  return (
    <Card style={{ height: '200px', overflowY: 'scroll' }}>
      <Row>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <Radio value={contact?.churchDeskId}>
            <Space direction="horizontal">
              <CdUserAvatar picture={contact?.picture} size="large" />
            </Space>
          </Radio>
          <Typography.Link
            href={getStateUrl('app.private.people.contacts.view', {
              id: contact?.churchDeskId,
            })}
            target="_blank"
          >
            <Space direction="horizontal">
              {`${contact?.firstName} ${contact?.lastName}` || contact?.email}
              <CdOpenInNewWindow />
            </Space>
          </Typography.Link>
        </Col>
        <Divider />
        <Col span={24}>
          <CdVerticalSpace size={0}>
            {contact?.churchDeskId && (
              <Space direction="horizontal">
                <Typography.Text>
                  {gettextCatalog.getString('ChurchDesk ID')}:
                </Typography.Text>
                {contact?.churchDeskId}
              </Space>
            )}

            {contact?.memberRegistryId && (
              <Space direction="horizontal">
                <Typography.Text>
                  {gettextCatalog.getString('External member registry id')}:
                </Typography.Text>
                {contact?.memberRegistryId}
              </Space>
            )}
            {contact?.email && (
              <Space direction="horizontal">
                <Typography.Text>
                  {gettextCatalog.getString('Email')}:
                </Typography.Text>
                {contact?.email}
              </Space>
            )}
            {contact?.phoneNumber && (
              <Space direction="horizontal">
                <Typography.Text>
                  {gettextCatalog.getString('Phone number')}:
                </Typography.Text>
                {contact?.phoneNumber}
              </Space>
            )}
            {!every(contact?.address, isNull) && (
              <Space direction="horizontal">
                <Typography.Text>
                  {gettextCatalog.getString('Address')}:
                </Typography.Text>
                {formatAddress({
                  street: contact?.address?.street,
                  postCode: contact?.address?.postCode,
                  city: contact?.address?.city,
                  countryIso2: contact?.address.country,
                })}
              </Space>
            )}
            {contact?.contactCreated && (
              <Space direction="horizontal">
                <Typography.Text>
                  {gettextCatalog.getString('Contact created')}:
                </Typography.Text>
                {moment(contact?.contactCreated).format('LLL')}
              </Space>
            )}
          </CdVerticalSpace>
        </Col>
      </Row>
    </Card>
  );
};
