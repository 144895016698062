import { Button, Space } from 'antd';
import React, { useMemo } from 'react';

import gettextCatalog from '@/react/services/I18nService';
import { useBookingPagesActions } from '@/react/settings/booking-pages/hooks/useBookingPagesActions';
import {
  CdDeleteIcon,
  CdEditIcon,
  CdTableIcon,
} from '@/react/shared/components/Icons';
import CdTable, { ColumnType } from '@/react/shared/components/cd-table';
import { AvailabilityTaxonomy } from '@/react/taxonomies/services/Taxonomies';

export const BookingPagesTable = () => {
  const { taxonomies, editBookingPage, deleteBookingPage } =
    useBookingPagesActions();
  const columns: ColumnType<AvailabilityTaxonomy>[] = useMemo(
    () => [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
      },
      {
        key: 'actions',
        title: '',
        width: 200,
        render: (_, bookingPage) => (
          <Space>
            <Button
              icon={<CdEditIcon />}
              onClick={() => editBookingPage(bookingPage)}
            >
              {gettextCatalog.getString('Edit')}
            </Button>
            <Button
              icon={<CdDeleteIcon />}
              danger
              onClick={() => deleteBookingPage(bookingPage)}
            >
              {gettextCatalog.getString('Delete')}
            </Button>
          </Space>
        ),
      },
    ],
    [deleteBookingPage, editBookingPage]
  );

  return (
    <CdTable<AvailabilityTaxonomy>
      columns={columns}
      data={{
        items: (taxonomies ?? []) as AvailabilityTaxonomy[],
        total: taxonomies?.length ?? 0,
      }}
      emptyStateProps={{
        title: gettextCatalog.getString('No Booking Pages'),
        EmptyStateIcon: <CdTableIcon />,
      }}
    />
  );
};
