import gettextCatalog from '@/react/services/I18nService';
import { FormBookingHours } from '@/react/settings/booking-pages/types/availability-taxonomy.types';
import { validationRule } from '@/react/shared/antd/validations/validation-rule';

export const aliasValidator = validationRule<string>((value) => {
  if (!value.match(/^[a-z0-9-]+$/)) {
    throw new Error(
      gettextCatalog.getString(
        'Alias can only contain lowercase letters, numbers and hyphens'
      )
    );
  }
});

export const hasOverlappingTime = validationRule<FormBookingHours>((value) => {
  const weekdaysWithOverlappingTime = Object.entries(value)
    .map(([k, v]) => {
      if (v.enabled) {
        const intervals = v.data;
        for (let i = 0; i < intervals.length; i++) {
          for (let j = i + 1; j < intervals.length; j++) {
            if (
              intervals[i].start < intervals[j].end &&
              intervals[i].end > intervals[j].start
            ) {
              return k;
            }
          }
        }
      }
    })
    .filter(Boolean);
  if (weekdaysWithOverlappingTime.length > 0) {
    throw new Error(
      gettextCatalog
        .getString('%s have overlapping time')
        .replace('%s', weekdaysWithOverlappingTime.join(', '))
    );
  }
});
