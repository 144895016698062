import React, { useEffect } from 'react';
import { Col, Form, Input, Row, Space, Typography } from 'antd';

import { useDuplicateContact } from '../hooks/useDuplicateContact';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import gettextCatalog from '@/react/services/I18nService';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { ContactGroupComponent } from '@/react/people/duplicate/components/ContactGroupComponent';
import { useDuplicateColumns } from '@/react/people/duplicate/hooks/useDuplicateColumns';

export const openDuplicateContactMergeModal = createCdModal<{
  duplicateId: number;
}>(({ setModalProps, duplicateId }) => {
  const {
    formInitialValue,
    duplicateContactMergeForm,
    rejectDuplicateContactMerge,
    duplicateRecordData,
    isLoading,
    mergeHandler,
  } = useDuplicateContact(duplicateId);
  const { openRejectConfirmModal } = useDuplicateColumns();

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: gettextCatalog.getString('Merge duplicate contacts'),
      width: 900,
      okText: gettextCatalog.getString('Merge'),
      alternativeButtonText: gettextCatalog.getString('Reject'),
      handleAlternativeButtonClick: () => openRejectConfirmModal(duplicateId),
      onOk: mergeHandler,
    });
  }, [
    setModalProps,
    duplicateId,
    mergeHandler,
    rejectDuplicateContactMerge,
    openRejectConfirmModal,
  ]);

  return isLoading ? (
    <CdrPageLoader />
  ) : (
    <Form
      form={duplicateContactMergeForm}
      initialValues={formInitialValue}
      colon={false}
      onValuesChange={(changedValues) => {
        if (changedValues.selectedContact) {
          const selectedContact = changedValues.selectedContact;
          const contact =
            duplicateRecordData?.contactOne?.churchDeskId === selectedContact
              ? duplicateRecordData?.contactOne
              : duplicateRecordData?.contactTwo;

          duplicateContactMergeForm.setFieldsValue({
            selectedEmail: contact?.email || '',
          });
          duplicateContactMergeForm.setFieldsValue({
            selectedPhoneNumber: contact?.phoneNumber || '',
          });
        }
      }}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Row align="middle">
            <Col span={24}>
              <Form.Item name="selectedContact">
                <ContactGroupComponent
                  duplicateRecordData={duplicateRecordData}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Typography.Text style={{ fontWeight: 600 }}>
            {gettextCatalog.getString(
              'Confirm the contact information for the final contact',
              null,
              'Merge Duplicate Contacts'
            )}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Form.Item
            label={gettextCatalog.getString('Email')}
            name="selectedEmail"
            dependencies={['selectedContact']}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={gettextCatalog.getString('Phone')}
            name="selectedPhoneNumber"
            dependencies={['selectedContact']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Typography.Text style={{ fontWeight: 600 }}>
            {gettextCatalog.getString(
              'What happens when I merge the contacts?',
              null,
              'Merge Duplicate Contacts'
            )}
          </Typography.Text>
          <Space>
            <ul style={{ margin: 0 }}>
              <li>
                {gettextCatalog.getString(
                  'Once you merge the contact, this cannot be undone. The duplicate contact will be deleted',
                  undefined,
                  'Merge Duplicate Contacts'
                )}
              </li>
              <li>
                {gettextCatalog.getString(
                  'The communication activity for both contacts will be available in the final contact',
                  undefined,
                  'Merge Duplicate Contacts'
                )}
              </li>
              <li>
                {gettextCatalog.getString(
                  'All given consents and list memberships will be moved to the final contact.',
                  undefined,
                  'Merge Duplicate Contacts'
                )}
              </li>
              <li>
                {gettextCatalog.getString(
                  'No data on the primary contact will be overwritten. Only empty fields on the primary contact will get data from the duplicate contact',
                  undefined,
                  'Merge Duplicate Contacts'
                )}
              </li>
            </ul>
          </Space>
        </Col>
      </Row>
    </Form>
  );
});
