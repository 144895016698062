import React from 'react';
import { Avatar, Button, Col, Row, Spin } from 'antd';
import styled from 'styled-components';

import { SimplePeopleForm } from '../../SimplePeopleForm';
import { useEditContactProfileBasicInformation } from '../../hooks/useContactProfileInformation';
import UploadFile, { ImageHelperText } from '../UploadFile';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';
import { gettextCatalog } from '@/react/services/I18nService';

const UploadContainer = styled.div`
border: 1px dashed #d9d9d9;
margin-right: 24px;
`;

export const showEditBasicInformationModal = createCdModal<{
  contactId: number;
  createMode?: boolean;
}>(({ setModalProps, contactId, createMode }) => {
  const {
    form,
    salutations,
    formalSalutations,
    showChurchSelector,
    churches,
    uploadContactPicture,
    removeContactPicture,
    handleChange,
    value,
    imageUrl,
    initialValues,
    isPending,
  } = useEditContactProfileBasicInformation({
    setModalProps,
    contactId,
  });
  return (
    <Spin spinning={isPending || !initialValues}>
      <Row>
        {!createMode && (
          <Col span={12}>
            <UploadContainer>
              <UploadFile
                uploadFile={uploadContactPicture}
                maxFileSize={10}
                allowedFileExtensions={['PNG', 'JPEG', 'GIF', 'BMP']}
                multiple={false}
                value={value}
                onChange={handleChange}
              >
                <Avatar src={imageUrl || '/img/user-default.png'} size={88} />
                <ImageHelperText>
                  {gettextCatalog.getString(
                    'Drop an image or click anywhere to browse'
                  )}
                </ImageHelperText>
                <p>{gettextCatalog.getString('JPEG, PNG, GIF or BMP')}</p>
                {imageUrl && (
                  <Button onClick={removeContactPicture}>
                    {gettextCatalog.getString('Remove')}
                  </Button>
                )}
              </UploadFile>
            </UploadContainer>
          </Col>
        )}
        <Col span={createMode ? 24 : 12}>
          <SimplePeopleForm
            person={initialValues}
            createMode={createMode}
            form={form}
            salutations={salutations}
            formalSalutations={formalSalutations}
            showChurchSelector={showChurchSelector}
            churches={churches}
          />
        </Col>
      </Row>
    </Spin>
  );
});
