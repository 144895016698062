// eslint-disable-next-line filename-rules/match
import { Rule } from 'antd/es/form';
import { isValidPhoneNumber } from 'libphonenumber-js';
import moment, { Moment } from 'moment';
import React from 'react';

import { CdPhone } from '../../components/CdPhoneInput';

import { validationRule } from './validation-rule';

import { gettextCatalog } from '@/react/services/I18nService';
import { Translation } from '@/react/shared/antd/validations/Translation';

export const required = (message?: string): Rule => ({
  required: true,
  message: message || <Translation text="Field is required." />,
});

export const emailFormat: Rule = {
  type: 'email',
  message: <Translation text="This is not a valid email." />,
};

export const integerFormat: Rule = {
  type: 'integer',
  message: <Translation text="The value must be a whole number." />,
};

export const positiveNumberFormat: Rule = {
  validator(rule, value) {
    if (
      (value && value > 0) ||
      value === 0 ||
      value === null ||
      value === undefined
    ) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        gettextCatalog.getString('Negative values are not allowed.')
      );
    }
  },
};

export const minNumber = (min: number): Rule => ({
  type: 'number',
  min,
  message: (
    <Translation
      text="Minimum value is {{min}}"
      params={{ min }}
      context="When validating whether a number is above a certain value. E.g. minimum value is 10."
    />
  ),
});

export const maxNumber = (max: number): Rule => ({
  type: 'number',
  max,
  message: (
    <Translation
      text="Maximum value is {{max}}"
      params={{ max }}
      context="When validating whether a number is above a certain value. E.g. maximum value is 10."
    />
  ),
});

export const maxLength = (max: number): Rule => ({
  type: 'string',
  max,
  message: (
    <Translation
      text="Maximum length is {{max}}"
      params={{ max }}
      context="When validating whether a string is above a certain length. E.g. maximum length is 10."
    />
  ),
});

export const dateIsAfter = (date: Moment): Rule => ({
  validator(rule, value) {
    if (!value || (moment.isMoment(value) && value.isAfter(date))) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        gettextCatalog.getString(
          'Date cannot be before {{date}}.',
          { date: date.format(gettextCatalog.getLongDateFormat('L')) },
          'When validating whether a date is before another. E.g. Date cannot be before 04/03/2024.'
        )
      );
    }
  },
});

export const phoneNumber: Rule = {
  validator(_, value: CdPhone) {
    try {
      if (
        !value?.phone?.length ||
        isValidPhoneNumber(`+${value.code}${value.phone}`)
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject(
          new Error(
            gettextCatalog.getString('Please input a valid phone number')
          )
        );
      }
    } catch (e) {
      // Mute errors
      return Promise.reject(
        new Error(gettextCatalog.getString('Please input a valid phone number'))
      );
    }
  },
};

export const isInArray = <T,>(array: T[], message?: string) =>
  validationRule((value: T) => {
    if (array && !array?.includes(value)) {
      throw new Error(
        message ||
          gettextCatalog.getString('The selected value is not in the list.')
      );
    }
  });
