import {
  Button,
  Collapse,
  CollapseProps,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';

dayjs.extend(customParseFormat);

import { FormBookingHours } from '../../types/availability-taxonomy.types';

import { TimeRangeWithoutConfirm } from './TimeRangeWithoutConfirm';
import { useWeeklyScheduleEvents } from './useWeeklyScheduleEvents';

import { Translation } from '@/react/shared/antd/validations/Translation';
import { CdDeleteIcon, CdPlus } from '@/react/shared/components/Icons';
import { Weekdays } from '@/react/shared/models/taxonomy';

const TRANSLATED_WEEKDAYS: Record<Weekdays, React.ReactNode> = {
  [Weekdays.Monday]: <Translation text="Monday" />,
  [Weekdays.Tuesday]: <Translation text="Tuesday" />,
  [Weekdays.Wednesday]: <Translation text="Wednesday" />,
  [Weekdays.Thursday]: <Translation text="Thursday" />,
  [Weekdays.Friday]: <Translation text="Friday" />,
  [Weekdays.Saturday]: <Translation text="Saturday" />,
  [Weekdays.Sunday]: <Translation text="Sunday" />,
};

type WeeklyScheduleProps = {
  value?: FormBookingHours;
  onChange?: (value: FormBookingHours) => void;
};

export const WeeklySchedule = ({ value, onChange }: WeeklyScheduleProps) => {
  const {
    expandedWeekdays,
    setExpandedWeekdays,
    onWeekdayEnabledChange,
    onTimeRangeChange,
    onTimeRangeRemove,
    onTimeRangeAdd,
  } = useWeeklyScheduleEvents(value, onChange);
  const items: CollapseProps['items'] = Object.values(Weekdays).map(
    (weekDay) => {
      const currentData = value[weekDay];
      return {
        key: weekDay,
        label: (
          <Space
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Switch
              checked={currentData.enabled}
              onChange={onWeekdayEnabledChange(weekDay)}
            />
            <Typography.Text>{TRANSLATED_WEEKDAYS[weekDay]}</Typography.Text>
          </Space>
        ),
        collapsible: currentData.enabled ? undefined : 'disabled',
        showArrow: currentData.enabled,
        children: (
          <Space direction="vertical">
            {currentData.data.map((item, index) => (
              <Row
                align="middle"
                key={index + item.start?.toString() + item.end?.toString()}
              >
                <Space>
                  <TimeRangeWithoutConfirm
                    defaultValue={[
                      item.start ? dayjs(item.start, 'HH:mm') : undefined,
                      item.end ? dayjs(item.end, 'HH:mm') : undefined,
                    ]}
                    minuteStep={5}
                    format="HH:mm"
                    size="large"
                    showNow={false}
                    onChange={onTimeRangeChange(weekDay, item)}
                  />

                  <Button
                    type="text"
                    danger
                    icon={<CdDeleteIcon />}
                    style={{
                      visibility:
                        currentData.data.length > 1 ? 'visible' : 'hidden',
                    }}
                    onClick={onTimeRangeRemove(weekDay, item)}
                  />

                  {index === currentData.data.length - 1 && (
                    <Button
                      type="text"
                      icon={<CdPlus />}
                      onClick={onTimeRangeAdd(weekDay)}
                    />
                  )}
                </Space>
              </Row>
            ))}
          </Space>
        ),
      };
    }
  );
  return (
    <Collapse
      items={items}
      expandIconPosition="end"
      activeKey={expandedWeekdays}
      onChange={setExpandedWeekdays as any}
    />
  );
};
