import { TimePicker } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledRangePicker = styled(TimePicker.RangePicker)`
    min-width: 350px;
`;
type TimeRangeWithoutConfirmProps = React.ComponentProps<
  typeof TimePicker.RangePicker
>;

// This component is a workaround for a missing `needConfirm` prop in our current AntD version
// It will trigger the `onChange` event without explicitly pressing "OK" in the dropdown
export const TimeRangeWithoutConfirm = (
  props: TimeRangeWithoutConfirmProps
) => {
  const { onChange } = props;
  return (
    <StyledRangePicker
      {...props}
      onCalendarChange={(dates) => {
        if (dates[0] && dates[1]) {
          onChange(dates, ['', '']);
        }
      }}
    />
  );
};
