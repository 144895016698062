import { Button, Space } from 'antd';
import React from 'react';

import { showBookingPageSettingsModal } from './BookingPageSettingsModal';

import gettextCatalog from '@/react/services/I18nService';
import { BookingPagesTable } from '@/react/settings/booking-pages/BookingPagesTable';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';

export const BookingPagesPage = () => (
  <CdPage
    pageHeaderProps={{
      title: gettextCatalog.getString('Booking Pages'),
      subTitle: gettextCatalog.getString('Manage your booking pages'),
      extra: (
        <Space>
          <Button
            type="primary"
            onClick={() => showBookingPageSettingsModal({})}
          >
            {gettextCatalog.getString('New Booking Page')}
          </Button>
        </Space>
      ),
    }}
  >
    <BookingPagesTable />
  </CdPage>
);
