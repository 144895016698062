import React from 'react';

import gettextCatalog from '../../../services/I18nService';
import {
  CdSelect,
  CdSelectProps,
} from '../../../shared/components/cd-select/CdSelect';
import { useTaxonomies } from '../../hooks/use-taxonomies';
import { AvailabilityTaxonomy } from '../../../taxonomies/services/Taxonomies';
import { CategoryType } from '../../../shared/models/category';

interface BookingOptionsSelectProps
  extends Pick<CdSelectProps, 'value' | 'onChange'> {
  taxonomyId: number;
}

export const BookingOptionsSelect = ({
  taxonomyId,
  ...props
}: BookingOptionsSelectProps) => {
  const { taxonomies } = useTaxonomies<AvailabilityTaxonomy>(
    CategoryType.availability
  );
  const bookingOptions =
    taxonomies?.find((taxonomy) => taxonomy.id === taxonomyId)
      ?.bookingOptions || [];

  return (
    <CdSelect
      options={bookingOptions.map((option) => ({
        value: option.id,
        label: option.name,
      }))}
      mode="multiple"
      maxTagCount="responsive"
      placeholder={gettextCatalog.getString(
        'Choose an option',
        null,
        'Booking option'
      )}
      emptyText={gettextCatalog.getString(
        'Select a category to view available booking options'
      )}
      disabled={!taxonomyId}
      {...props}
    />
  );
};
