import { Form } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useSaveNewTaxonomyMutation,
  useSingleTaxonomyQuery,
  useUpdateTaxonomyMutation,
} from '../store/taxonomyHooks';
import { AvailabilityTaxonomyFormData } from '../types/availability-taxonomy.types';

import gettextCatalog from '@/react/services/I18nService';
import {
  EMPTY_FORM_DATA,
  convertFormDataToTaxonomy,
  createNewBookingOption,
  existingTaxonomyToFormData,
} from '@/react/settings/booking-pages/hooks/utils';

export const useAvailabilityFormData = (taxonomyId: number | undefined) => {
  const [activeItem, setActiveItem] = useState('details');
  const [form] = Form.useForm<AvailabilityTaxonomyFormData>();
  // eslint-disable-next-line destructuring/no-rename
  const { data: existingTaxonomy, isLoading: isLoadingExistingTaxonomy } =
    useSingleTaxonomyQuery(taxonomyId);

  // eslint-disable-next-line destructuring/no-rename
  const { mutateAsync: saveNewTaxonomy, isPending: isPendingSavingNew } =
    useSaveNewTaxonomyMutation();
  // eslint-disable-next-line destructuring/no-rename
  const { mutateAsync: updateTaxonomy, isPending: isPendingUpdate } =
    useUpdateTaxonomyMutation();
  const onSave = useCallback(async () => {
    await form.validateFields({ validateOnly: false });
    if (!existingTaxonomy) {
      await saveNewTaxonomy(
        convertFormDataToTaxonomy(undefined, form.getFieldsValue(true))
      );
    } else {
      await updateTaxonomy(
        convertFormDataToTaxonomy(existingTaxonomy, form.getFieldsValue(true))
      );
    }
  }, [existingTaxonomy, form, saveNewTaxonomy, updateTaxonomy]);

  const onSaveAndContinue = useCallback(async () => {
    await onSave();
    return { preventClose: true };
  }, [onSave]);

  const onAddBookingOption = useCallback(() => {
    const newBookingOptions = [
      ...(form.getFieldValue('bookingOptions') ?? []),
      createNewBookingOption(),
    ];
    form.setFieldValue('bookingOptions', newBookingOptions);
    return newBookingOptions.length - 1;
  }, [form]);

  const deleteBookingOption = useCallback(
    (optionIndex: number) => {
      const newOptionsValue = form
        .getFieldsValue(true)
        .bookingOptions.filter((_, index) => index !== optionIndex);
      setActiveItem('details');
      form.setFieldValue('bookingOptions', newOptionsValue);
    },
    [form]
  );

  useEffect(() => {
    if (activeItem === 'add-option') {
      setActiveItem('option-' + onAddBookingOption());
    }
  }, [activeItem, onAddBookingOption]);

  const defaultEmptyFormData = useMemo(
    () =>
      existingTaxonomy
        ? existingTaxonomyToFormData(existingTaxonomy)
        : EMPTY_FORM_DATA(),
    [existingTaxonomy]
  );

  const currentFormData = form.getFieldsValue(true);
  const currentBookingOptions: AvailabilityTaxonomyFormData['bookingOptions'] =
    currentFormData.bookingOptions;

  return {
    form,
    initialValues: existingTaxonomy
      ? existingTaxonomyToFormData(existingTaxonomy)
      : defaultEmptyFormData,
    isLoading: isPendingSavingNew || isPendingUpdate,
    onSave,
    onSaveAndContinue,
    onAddBookingOption,
    deleteBookingOption,
    isLoadingExistingTaxonomy,
    title:
      existingTaxonomy?.name ??
      gettextCatalog.getString('Unnamed Availability Type'),
    isExistingTaxonomy: !!existingTaxonomy,
    activeItem,
    setActiveItem,
    currentBookingOptions,
    formData: existingTaxonomy?.config?.form,
  };
};
