import React from 'react';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import gettextCatalog from '@/react/services/I18nService';
import { CdServerSideTable } from '@/react/shared/components/cd-server-side-table/CdServerSideTable';
import { CdDuplicateContactIcon } from '@/react/shared/components/Icons';
import { DuplicateContact } from '@/react/people/duplicate/types/Types';
import { GetDuplicateContactColumns } from '@/react/people/duplicate/components/GetDuplicateContactColumns';
import { getDuplicateContacts } from '@/react/people/duplicate/store/duplicateContacts';

export const DuplicateContactsWrapper = () => (
  <CdPage
    pageHeaderProps={{
      title: gettextCatalog.getString(
        'Manage duplicate contacts',
        null,
        'Merge Duplicate Contacts'
      ),
      subTitle: gettextCatalog.getString(
        'View and take action on duplicate contacts.',
        null,
        'Merge Duplicate Contacts'
      ),
    }}
  >
    <CdServerSideTable<DuplicateContact>
      noStyle
      pagedQuery={getDuplicateContacts()}
      columns={GetDuplicateContactColumns()}
      rowKey="id"
      emptyStateProps={{
        EmptyStateIcon: <CdDuplicateContactIcon />,
        title: gettextCatalog.getString(
          'No duplicate contacts found',
          null,
          'Merge Duplicate Contacts'
        ),
      }}
    />
  </CdPage>
);
