import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import { map } from 'lodash';

import { ConflictingPersonWithRelation } from '../services/people.service';

import { AttachFileInterface } from './useMessage';
import {
  useGetProfileInfo,
  useGetStaticFields,
  useGetStaticFieldsOptions,
  useSaveContactProfileInfo,
  useUpdateContactProfileInfo,
} from './usePeopleContactQueryHooks';

import { gettextCatalog } from '@/react/services/I18nService';
import {
  handleAntFormValidationErrors,
  handleError,
} from '@/react/services/ErrorHandlingService';
import { ModalType } from '@/react/shared/components/cd-modal-new/CdModal2';
import { useChurchesV3 } from '@/react/contributions/store/contributions';
import { mainApi } from '@/react/api';

export const useEditContactProfileInformation = (
  contactId: number,
  accessFields?: any
) => {
  const { staticFieldsOptions } = useGetStaticFieldsOptions();
  const [conflictingPeopleByEmail, setConflictingPeopleByEmail] = useState<
    ConflictingPersonWithRelation[]
  >([]);
  const [conflictingPeopleByPhone, setConflictingPeopleByPhone] = useState<
    ConflictingPersonWithRelation[]
  >([]);
  const [initialValues, setInitialValues] = useState<any>(null);
  const conflictingPeopleByEmailRef = useRef(conflictingPeopleByEmail);
  const conflictingPeopleByPhoneRef = useRef(conflictingPeopleByPhone);
  const initialValuesRef = useRef(initialValues);

  useEffect(() => {
    conflictingPeopleByEmailRef.current = conflictingPeopleByEmail;
  }, [conflictingPeopleByEmail]);

  useEffect(() => {
    conflictingPeopleByPhoneRef.current = conflictingPeopleByPhone;
  }, [conflictingPeopleByPhone]);

  useEffect(() => {
    initialValuesRef.current = initialValues;
  }, [initialValues]);
  const { profileInfo, isPending, isRefetching } = useGetProfileInfo(contactId);
  const { updateContactInfo } = useUpdateContactProfileInfo(contactId);
  const civilStatusOptions = staticFieldsOptions?.civilStatus
    ? Object.keys(staticFieldsOptions?.civilStatus).map((option) => ({
        value: option,
        label: staticFieldsOptions?.civilStatus[option],
      }))
    : [];
  const allergyOptions = staticFieldsOptions?.allergy
    ? Object.keys(staticFieldsOptions?.allergy).map((option) => ({
        value: option,
        label: staticFieldsOptions?.allergy[option],
      }))
    : [];
  const dietaryOptions = staticFieldsOptions?.dietary
    ? Object.keys(staticFieldsOptions?.dietary).map((option) => ({
        value: option,
        label: staticFieldsOptions?.dietary[option],
      }))
    : [];
  const denominationOptions = staticFieldsOptions?.denomination
    ? Object.keys(staticFieldsOptions?.denomination).map((option) => ({
        value: option,
        label: staticFieldsOptions?.denomination[option],
      }))
    : [];

  useEffect(() => {
    const processInitialValues = () => {
      const value = { ...profileInfo };
      if (value) {
        if (value.phone && accessFields?.phone?.canEdit) {
          const phone = parsePhoneNumber(value.phone);
          value.phone = {
            phone: phone.nationalNumber,
            code: phone.countryCallingCode,
            short: phone.country,
          };
        } else {
          delete value.phone;
        }

        if (value.homePhone && accessFields?.homePhone?.canEdit) {
          const phone = parsePhoneNumber(value.homePhone);
          value.homePhone = {
            phone: phone.nationalNumber,
            code: phone.countryCallingCode,
            short: phone.country,
          };
        } else {
          delete value.homePhone;
        }
        if (value.workPhone && accessFields?.workPhone?.canEdit) {
          const phone = parsePhoneNumber(value.workPhone);
          value.workPhone = {
            phone: phone.nationalNumber,
            code: phone.countryCallingCode,
            short: phone.country,
          };
        } else {
          delete value.workPhone;
        }
        value.birthday = value.birthday ? moment(value.birthday) : undefined;
        value.baptismDate = value.baptismDate
          ? moment(value.baptismDate)
          : undefined;
        value.civilMarriageDate = value.civilMarriageDate
          ? moment(value.civilMarriageDate)
          : undefined;
        value.confirmationDate = value.confirmationDate
          ? moment(value.confirmationDate)
          : undefined;
        value.membershipDate = value.membershipDate
          ? moment(value.membershipDate)
          : undefined;
        return value;
      }
      return null;
    };

    if (profileInfo) {
      setInitialValues(processInitialValues());
    }
  }, [profileInfo, accessFields]);
  const saveContactProfileInfo = async ({ form }) => {
    const errorValues = await form.validateFields().catch((err) => {
      handleAntFormValidationErrors(err);
    });
    if (!errorValues) return;
    const values = form.getFieldsValue();
    const relations: { relation: string; person: { personId: number } }[] = [];
    // Process conflicts with contacts sharing the selected email address
    if (conflictingPeopleByEmailRef.current.length > 0) {
      const relationsByEmail = conflictingPeopleByEmailRef.current.map(
        (item) => ({
          relation: item.relation,
          person: { personId: item.conflictingPerson.id },
        })
      );
      relations.push(...relationsByEmail);
    }

    // Process conflicts with contacts sharing the selected phone number
    if (conflictingPeopleByPhoneRef.current.length > 0) {
      const relationsByPhone = conflictingPeopleByPhoneRef.current.map(
        (item) => ({
          relation: item.relation,
          person: { personId: item.conflictingPerson.id },
        })
      );
      relations.push(...relationsByPhone);
    }
    const payload = { ...initialValuesRef.current, ...values };
    if (relations.length > 0) {
      let initialRelations = payload.relations || [];

      relations.forEach((relation) => {
        initialRelations = initialRelations.filter(
          (item) => item.person.personId !== relation.person.personId
        );
      });
      payload.relations = initialRelations.concat(relations);
    }
    const preparedData = { ...payload };
    if (payload.phone.phone) {
      preparedData.phone = `+${payload.phone.code}${payload.phone.phone}`;
    } else {
      preparedData.phone = null;
    }
    if (payload.homePhone.phone) {
      preparedData.homePhone = `+${payload.homePhone.code}${payload.homePhone.phone}`;
    } else {
      preparedData.homePhone = null;
    }
    if (payload.workPhone.phone) {
      preparedData.workPhone = `+${payload.workPhone.code}${payload.workPhone.phone}`;
    } else {
      preparedData.workPhone = null;
    }
    if (payload.birthday) {
      preparedData.birthday = payload.birthday.format('YYYY-MM-DD');
    }
    if (payload.baptismDate) {
      preparedData.baptismDate = payload.baptismDate.format('YYYY-MM-DD');
    }
    if (payload.civilMarriageDate) {
      preparedData.civilMarriageDate =
        payload.civilMarriageDate.format('YYYY-MM-DD');
    }
    if (payload.confirmationDate) {
      preparedData.confirmationDate =
        payload.confirmationDate.format('YYYY-MM-DD');
    }
    if (payload.membershipDate) {
      preparedData.membershipDate = payload.membershipDate.format('YYYY-MM-DD');
    }
    preparedData.fields = undefined;
    await updateContactInfo({ payload: preparedData, contactId });
  };

  return {
    isPending,
    isRefetching,
    initialValues,
    saveContactProfileInfo,
    setConflictingPeopleByEmail,
    setConflictingPeopleByPhone,
    conflictingPeopleByEmail,
    conflictingPeopleByPhone,
    civilStatusOptions,
    allergyOptions,
    dietaryOptions,
    denominationOptions,
  };
};

export const useCreateContactProfileBasicInformation = () => {
  const { createContact } = useSaveContactProfileInfo();
  const [form] = Form.useForm();
  const [conflictingPeopleByEmail, setConflictingPeopleByEmail] = useState<
    ConflictingPersonWithRelation[]
  >([]);
  const [conflictingPeopleByPhone, setConflictingPeopleByPhone] = useState<
    ConflictingPersonWithRelation[]
  >([]);
  const conflictingPeopleByEmailRef = useRef(conflictingPeopleByEmail);
  const conflictingPeopleByPhoneRef = useRef(conflictingPeopleByPhone);

  const churchesQuery = useChurchesV3('people', 'create');
  const churches = churchesQuery.data;
  const showChurchSelector = window?.cdApp?.showChurchSelector;
  useEffect(() => {
    conflictingPeopleByEmailRef.current = conflictingPeopleByEmail;
  }, [conflictingPeopleByEmail]);

  useEffect(() => {
    conflictingPeopleByPhoneRef.current = conflictingPeopleByPhone;
  }, [conflictingPeopleByPhone]);
  const handleSave = useCallback(
    async (saveAndCreateAnother: boolean) => {
      await form.validateFields().then(async (values) => {
        const payload = { ...values };
        // Set the selected churches
        const selectedChurches = showChurchSelector
          ? payload.churches
          : map(churches, 'id');
        payload.churches = map(selectedChurches, (church) => ({
          id: church,
        }));

        if (payload.phone?.code && payload.phone.phone) {
          payload.phone = '+' + payload.phone.code + payload.phone.phone;
        } else {
          payload.phone = undefined;
        }
        const relations: {
          relation: string;
          person: { personId: number };
        }[] = [];
        // Process conflicts with contacts sharing the selected email address
        if (conflictingPeopleByEmailRef.current.length > 0) {
          const relationsByEmail = conflictingPeopleByEmailRef.current.map(
            (item) => ({
              relation: item.relation,
              person: { personId: item.conflictingPerson.id },
            })
          );
          relations.push(...relationsByEmail);
        }

        // Process conflicts with contacts sharing the selected phone number
        if (conflictingPeopleByPhoneRef.current.length > 0) {
          const relationsByPhone = conflictingPeopleByPhoneRef.current.map(
            (item) => ({
              relation: item.relation,
              person: { personId: item.conflictingPerson.id },
            })
          );
          relations.push(...relationsByPhone);
        }
        if (relations.length > 0) {
          payload.relations = relations;
        }

        await createContact({ payload, saveAndCreateAnother });
      });
    },
    [
      form,
      createContact,
      showChurchSelector,
      churches,
      conflictingPeopleByEmailRef,
      conflictingPeopleByPhoneRef,
    ]
  );

  return {
    form,
    setConflictingPeopleByEmail,
    setConflictingPeopleByPhone,
    showChurchSelector,
    churches,
    handleSave,
  };
};

export const useEditContactProfileBasicInformation = ({
  setModalProps,
  contactId,
}) => {
  const { profileInfo, isPending } = useGetProfileInfo(contactId);
  const { staticFieldsOptions } = useGetStaticFieldsOptions();
  const { updateContactInfo } = useUpdateContactProfileInfo(contactId);
  const [initialValues, setInitialValues] = useState(null);
  const [imageInfo, setImageInfo] = useState({
    url: null,
    id: null,
  });
  useEffect(() => {
    setImageInfo({
      url: profileInfo?.picture?.url,
      id: profileInfo?.picture?.id,
    });
    setInitialValues(profileInfo);
  }, [profileInfo]);
  const [uploadFileValue, setUploadFileValue] = useState([]);
  const [form] = Form.useForm();
  const salutations = staticFieldsOptions?.salutation
    ? Object.keys(staticFieldsOptions?.salutation).map((option) => ({
        value: option,
        label: staticFieldsOptions?.salutation[option],
      }))
    : [];
  const formalSalutations = staticFieldsOptions?.formalSalutation
    ? Object.keys(staticFieldsOptions?.formalSalutation).map((option) => ({
        value: option,
        label: staticFieldsOptions?.formalSalutation[option],
      }))
    : [];
  const churchesQuery = useChurchesV3('people', 'create');
  const churches = churchesQuery.data;
  const showChurchSelector = window?.cdApp?.showChurchSelector;
  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: gettextCatalog.getString('Edit name and image'),
      width: 900,
      maxBodyHeight: 80,
      onOk: async () => {
        await form.validateFields().then(async (values) => {
          const payload = { ...initialValues, ...values };
          // Set the selected churches
          const selectedChurches = showChurchSelector
            ? payload.churches
            : map(churches, 'id');
          payload.churches = map(selectedChurches, (church) => ({
            id: church,
          }));
          if (payload.phone?.code && payload.phone.phone) {
            payload.phone = '+' + payload.phone.code + payload.phone.phone;
          } else {
            payload.phone = undefined;
          }
          if (salutations.length > 0 && !payload.salutation) {
            payload.salutation = '';
          }
          payload.picture = imageInfo;
          updateContactInfo({ payload, contactId });
        });
      },
    });
  }, [
    churches,
    contactId,
    form,
    imageInfo,
    initialValues,
    salutations.length,
    setModalProps,
    showChurchSelector,
    updateContactInfo,
  ]);

  const uploadContactPicture = async ({
    file,
    onSuccess,
    onProgress,
    onError,
  }: AttachFileInterface) => {
    const formData = new FormData();
    formData.append('fromGallery', 'false');
    formData.append('file', file as any);
    try {
      const res = await mainApi.post<any>('/people/people/upload', formData, {
        onUploadProgress: (e) =>
          onProgress({ percent: (e.loaded / e.total) * 100, ...e }),
      });
      if (!res.ok) {
        handleError(res);
      } else {
        onSuccess(res?.data);
        setImageInfo({ url: res?.data?.url, id: res?.data?.id });
      }
      return res.data;
    } catch (e) {
      onError(e);
    }
  };
  const removeContactPicture = (e) => {
    e.stopPropagation();
    setImageInfo({ url: null, id: null });
    setUploadFileValue([]);
  };
  const handleChange = (info) => {
    setUploadFileValue(info.file);
  };

  return {
    form,
    salutations,
    formalSalutations,
    showChurchSelector,
    churches,
    uploadContactPicture,
    removeContactPicture,
    handleChange,
    value: uploadFileValue,
    imageUrl: imageInfo?.url,
    initialValues,
    isPending,
  };
};

export const usePeopleContactPersonalInfo = (contactId) => {
  const { staticFields } = useGetStaticFields();
  const { profileInfo } = useGetProfileInfo(contactId);
  const { staticFieldsOptions } = useGetStaticFieldsOptions();

  return {
    staticFields,
    profileInfo,
    staticFieldsOptions,
  };
};
