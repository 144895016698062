import React from 'react';
import { Typography } from 'antd';

import { FormSubmissionData } from '../types/enquiry';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';

export interface FormSubmissionViewerProps {
  submissionData: FormSubmissionData;
}

export const FormSubmissionViewer = (props: FormSubmissionViewerProps) => (
  <CdVerticalSpace size={0}>
    {props.submissionData?.map((section) => (
      <>
        <Typography.Title level={3}>{section?.label}</Typography.Title>
        <CdVerticalSpace>
          {section?.value?.map((value) => (
            <CdVerticalSpace size={0}>
              <Typography.Text type="secondary">{value?.label}</Typography.Text>
              {value?.value}
            </CdVerticalSpace>
          ))}
        </CdVerticalSpace>
      </>
    ))}
  </CdVerticalSpace>
);
