import daDK from 'antd/es/locale/da_DK';
import deDE from 'antd/es/locale/de_DE';
import enGB from 'antd/es/locale/en_GB';
import enUS from 'antd/es/locale/en_US';
import frFR from 'antd/es/locale/fr_FR';
import svSE from 'antd/es/locale/sv_SE';
import * as _ from 'lodash';
import { keys } from 'lodash';
import moment from 'moment';
import { $injector } from 'ngimport';

import cdApp from '@/react/config';

const DEFAULT_LANGUAGE = 'en';
const DEFAULT_LONG_DATE_FORMAT_KEY = 'L';

// To align with the rest of the system Cancel has been translated to `Annuller`.
// https://app.clubhouse.io/churchdesk/story/8406/the-cancel-cta-button-should-be-called-annuller-in-danish-and-not-afbryd
daDK.Modal.cancelText = 'Annuller';
daDK.Popconfirm.cancelText = 'Annuller';

/**
 * `!!!IMPORTANT NOTE ON USAGE!!!`
 *
 * In order for translations to work on `ts` and `tsx` files,
 * this file should be imported using the following variable
 * name:
 *
 * `import { gettextCatalog } from '@/react/services/I18nService';`
 *
 * Only when using that variable name, the invoking of the
 * `getString` function will be processed by the `extract`
 * script.
 */

/**
 * Return the ANT Design locale for the specified language.
 *
 * @param {string} [language] The language of the locale to be returned (defaults to the current organization's language)
 * @returns {object}
 */
const getANTDesignLocale = (language?: string) => {
  // Some languages are missing translations for some components.
  // Here we add them manually.
  const customTranslations = {
    Image: {
      preview: getString('Preview'),
    },
  };
  const processedLanguage = language
    ? _.toLower(language)
    : getOrganizationLanguage();
  switch (processedLanguage) {
    case 'da':
      return { ...customTranslations, ...daDK };
    case 'de':
      return { ...customTranslations, ...deDE };
    case 'en-gb':
      return { ...customTranslations, ...enGB };
    case 'sv':
      return { ...customTranslations, ...svSE };
    case 'fr':
      return { ...customTranslations, ...frFR };
    default:
      return { ...customTranslations, ...enUS };
  }
};

/**
 * Retrieves the current organization's language. Default value is 'en' (English).
 *
 * @returns {string}
 */
const getOrganizationLanguage = (): string =>
  _.toLower(
    _.get(window, 'cdApp.organization.locale.language', DEFAULT_LANGUAGE)
  );

/**
 * Return the long date format for MomentJS date formatting, based on the current locale.
 *
 * @param {string} [formatKey='L'] The "key" of the format to be returned (defaults to 'L')
 * @returns {string}
 */
const getLongDateFormat = (
  formatKey: moment.LongDateFormatKey = DEFAULT_LONG_DATE_FORMAT_KEY
): string => {
  const longDateFormat = moment.localeData().longDateFormat(formatKey);
  if (longDateFormat) return longDateFormat;
  return moment.localeData().longDateFormat(DEFAULT_LONG_DATE_FORMAT_KEY);
};

/**
 * Format a date object based on the organization's locale (language)
 * and the specified format parameter.
 *
 * @param {Date} date The date to format
 * @param {string} [formatKey='L'] The "key" of the format to be used (defaults to 'L')
 * @returns {string}
 */
const formatDate = (
  date: Date,
  formatKey: moment.LongDateFormatKey = DEFAULT_LONG_DATE_FORMAT_KEY
): string => {
  if (!date) return null;
  const longDateFormat = getLongDateFormat(formatKey);
  const organizationLanguage = getOrganizationLanguage();
  return moment(date).locale(organizationLanguage).format(longDateFormat);
};

const _angularGetString = (
  text: string,
  params?: any,
  msgctxt?: string
): string => {
  if (!$injector) return text;
  const gettextCatalog: any = $injector.get('gettextCatalog');

  return gettextCatalog.getString(text, params, msgctxt);
};

/**
 * Returns the translated string for the given text, with optional parameters and message context.
 *
 * @example <caption>For React files</caption>
 * gettextCatalog.getString('Please select')
 *
 * @example <caption>Inserting variables in the string</caption>
 * gettextCatalog.getString("Hello {{name}}", { name: "Ruben" });
 *
 * @example <caption>Replacing variables with smart functions</caption>
 * gettextCatalog.getString(
 *   'The internal note will be automatically deleted {{ days }} days after the event on {{ expiredDate }} as configured in your <link>data retention policy</link>',
 *   {
 *     days: expirationEventInternalNote,
 *     expiredDate: internalNoteExpiredDate,
 *     link: (text) =>
 *       `<a href="${settingUrl}" target='_blank'>${text}</a>`,
 *   }
 * )
 *
 * @param {string} text - The text to translate.
 * @param {Object} [params] - Optional parameters to insert into the translated string.
 * @param {string} [msgctxt] - Optional message context for the translation.
 * @returns {string} The translated string.
 */
const getString = (
  text: string,
  params?: Record<string, any>,
  msgctxt?: string
): string => {
  const angularString = _angularGetString(text, params, msgctxt);

  if (keys(params).length === 0) return angularString;
  const searchForProps = keys(params).join('|');
  const regex = new RegExp('<(' + searchForProps + ')>.+?</\\1>', 'gi');
  try {
    // @ts-ignore replaceAll will be rewritten by compiler to be supported
    return angularString.replaceAll(regex, (h) => {
      const tagMatch = h.match(/<(.+?)>/);
      const tag = tagMatch[1];

      const inner = h.match(new RegExp('<' + tag + '>(.+)' + '</' + tag + '>'));

      if (typeof params[tag] === 'function') {
        return params[tag](inner[1]);
      } else {
        return h;
      }
    });
  } catch {
    return angularString;
  }
};

/**
 * Used when you need to translate a js string such as getStringDynamic(ContentType.BLOG).
 * Using this function will ensure that the extracter will ignore the string.
 */
const getStringDynamic = (
  text: string,
  params?: any,
  msgctxt?: string
): string => {
  if (!$injector) return text;
  const gettextCatalog: any = $injector.get('gettextCatalog');
  return gettextCatalog.getString(text, ...params, msgctxt);
};

/**
 * Translate a plural string with the given context.
 *
 * @param {number} n the number of items
 * @param {string} string the singular version of the string
 * @param {string} stringPlural the plural version of the string
 * @param {string} [context] the variable context given to construct the above strings.
 * @example
 *  gettextCatalog.getPlural(count, '1 event selected', '{{ count }} events selected', { count: total });
 */
const getPlural = (
  n: number,
  string: string,
  stringPlural: string,
  context?: any
) => {
  // Workaround when we are running inside JEST with no AngularJS available.
  if (!$injector) {
    if (n === 1) {
      return string;
    } else {
      _.each(context, (value, key) => {
        stringPlural = stringPlural.replace(`{{ ${key} }}`, value);
      });
      return stringPlural;
    }
  }
  const gettextCatalog: any = $injector.get('gettextCatalog');
  return gettextCatalog.getPlural(n, string, stringPlural, context);
};

const getCurrentLanguage = (): string => {
  const gettextCatalog: any = $injector.get('gettextCatalog');
  return gettextCatalog.getCurrentLanguage();
};

const setCurrentLanguage = (language: string): Promise<void> => {
  const gettextCatalog: any = $injector.get('gettextCatalog');
  function getLanguageForGettextCatalog(lang) {
    if (_.includes(['da', 'sv', 'it', 'de', 'fr'], lang)) return lang;
    // In PoEditor.com 'en' is the equivalent of 'en-gb'
    return 'en';
  }
  const lang = getLanguageForGettextCatalog(language);

  let version = _.get(cdApp, 'release') || 'no-info';
  if (version.length > 30) {
    // Cloudflare deployment
    version = `CF-${version.slice(0, 7)}`;
  }

  return gettextCatalog.loadRemote(`js/${lang}.json?v=${version}`).then(() => {
    gettextCatalog.setCurrentLanguage(lang);
  });
};

export const gettextCatalog = {
  getANTDesignLocale,
  getOrganizationLanguage,
  getLongDateFormat,
  formatDate,
  getString,
  getStringDynamic,
  getPlural,
  getCurrentLanguage,
  setCurrentLanguage,
};

// Named to easily import using VSCode suggestions
export default gettextCatalog;
