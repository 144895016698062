import moment, { Moment } from 'moment';
import { capitalize, flatten, uniq } from 'lodash';

import cdApp from '../../../config';
import { DateInputValue } from '../../event-details/components/date-input/DateInput';

import { useRRule } from './use-rrule';

export const useBookingOptions = (props?: DateInputValue) => {
  const rruleQuery = useRRule(
    props?.rrule
      ? {
          startDate: props.startDate.toISOString(),
          endDate: props.endDate.toISOString(),
          rrule: props.rrule,
        }
      : null
  );

  const weekdayMap = getWeekdayMap();

  const affectedWeekdays = uniq(
    flatten(
      (rruleQuery.dates?.length > 0
        ? rruleQuery.dates.slice(
            0,
            rruleQuery.dates.length > 50 ? 50 : rruleQuery.dates.length
          )
        : props?.startDate
          ? [
              {
                startDate: moment(props.startDate),
                endDate: moment(props.endDate),
              },
            ]
          : []
      ) // Unlikely a non repeating pattern arises after 50 days
        .map((item) =>
          getDateRange(moment(item.endDate), moment(item.startDate)).map(
            (date) => date.locale('en').format('dddd').toLowerCase()
          )
        )
    )
  ).sort((a, b) => weekdayMap[a].index - weekdayMap[b].index) as WeekdayName[];

  return {
    affectedWeekdays,
    weekdayMap,
  };
};

export const getDateRange = (a: Moment, b: Moment): Moment[] => {
  const firstDate = a.isBefore(b) ? a : b;
  const lastDate = a.isBefore(b) ? b : a;

  if (lastDate.isBefore(firstDate))
    throw new Error('firstDate cannot be after lastDate');
  let date = moment(firstDate);
  const dates = [];
  while (date.isBefore(moment(lastDate).startOf('day'))) {
    dates.push(date);
    date = moment(date).add(1, 'day');
  }
  dates.push(moment(lastDate));
  return dates;
};

const getWeekdayMap = () =>
  Object.keys(WeekdayName).reduce(
    (acc, key, index) => {
      acc[WeekdayName[key]] = {
        index,
        name: capitalize(
          moment()
            .set('weekday', index)
            .locale(cdApp.organization.locale.language)
            .format('dddd')
        ),
      };
      return acc;
    },
    {} as Record<WeekdayName, { index: number; name: string }>
  );

export enum WeekdayName {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}
