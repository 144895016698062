import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiSearchParams, mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import {
  DuplicateContact,
  DuplicateContactResponse,
} from '@/react/people/duplicate/types/Types';

export const getDuplicateContacts = () => (params: ApiSearchParams) =>
  useQuery({
    queryKey: ['getDuplicateContacts', params],
    queryFn: async () => {
      const response = await mainApi.get<DuplicateContactResponse>(
        `people/duplicates`,
        { ...params }
      );
      if (!response.ok) {
        handleError(response);
        return {
          items: [],
          total: 0,
        };
      }
      return {
        items: response.data.duplicates,
        total: response.data.total,
      };
    },
  });

export const useDuplicateContactRecord = (duplicateId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ['useDuplicateContactRecord', duplicateId],
    queryFn: async () => {
      const response = await mainApi.get<DuplicateContact>(
        `people/duplicates/${duplicateId}`
      );
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response.data;
    },
  });
  return { duplicateRecordData: data, isLoading };
};

export const useMergeDuplicateContact = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      duplicateId,
      baseContactId,
      selectedEmail,
      selectedPhoneNumber,
    }: {
      duplicateId: number;
      baseContactId: number;
      selectedEmail: string;
      selectedPhoneNumber: string;
    }) => {
      const response = await mainApi.post(`/people/duplicates/merge`, {
        duplicateId,
        baseContactId,
        selectedEmail,
        selectedPhoneNumber,
      });
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getDuplicateContacts'],
      });
    },
  });
  return { mergeDuplicateContact: mutate };
};

export const useRejectDuplicateContactMerge = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async (duplicateId: number) => {
      const response = await mainApi.post(`/people/duplicates/reject`, {
        duplicateId: duplicateId,
      });
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getDuplicateContacts'],
      });
    },
  });
  return { rejectDuplicateContactMerge: mutate };
};
