import { Form } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pick } from 'lodash';

import { gettextCatalog } from '../../../services/I18nService';
import { EventType } from '../../models/calendar';
import cdApp from '../../../config';
import AuthorizationService from '../../../services/AuthorizationService';
import EventPopupService from '../../services/EventPopupService';
import {
  useGroups,
  useResources,
} from '../../../organization/store/organization';
import { openEventDrawer } from '../../event-details/EventDrawer';
import { AddEvent } from '../../store/main-view/Actions';
import NotificationService from '../../../services/NotificationService';
import { postEventAPI } from '../../store/events/event';
import { showConflicts } from '../../event-details/hooks/use-event';
import { extractResourceIds, findResource } from '../../helpers/resources';
import {
  getInitialEventVisibility,
  getVisibilityOptions,
} from '../../helpers/visibility';

import { generatePayloadFromValues, getFormattedDate } from './popover-helpers';
import { CreateEventPopupProps } from './CreateEventPopover';

export const useCreateEventPopup = (props: CreateEventPopupProps) => {
  const [form] = Form.useForm();
  const currentValues = Form.useWatch([], form);
  const { groupsWithUserAsMember, groups } = useGroups();
  const { resources } = useResources();
  const dispatch = useDispatch();

  const showResourceSelector = AuthorizationService.hasPermission('canBook');
  const canCreateAbsenceAndBook = AuthorizationService.hasPermission(
    'canCreateAbsenceAndBook'
  );

  const groupsWithSelectedUser = currentValues?.users
    ? groupsWithUserAsMember(currentValues.users, false)
    : groups;
  const selectedGroup = groups?.find(
    (group) => group.id === currentValues?.groupId
  );
  const groupOptions = groupsWithSelectedUser?.map((group) => ({
    value: group.id,
    label: group.name,
  }));

  const users = canCreateAbsenceAndBook
    ? Object.keys(cdApp.data.calendar.filters.users).map((userId) => ({
        value: Number(userId),
        label: cdApp.data.calendar.filters.users[userId].name,
      }))
    : [{ value: Number(cdApp.me.id), label: cdApp.me.fullName }];

  const visibilityOptions = getVisibilityOptions();

  const changeType = (type: EventType) =>
    form.setFieldsValue({
      type,
      mainCategory: null,
    });

  const onCreate = async () => {
    const values = await form.validateFields();
    const resourceIds = extractResourceIds(values.resources);
    const firstResource = findResource(resources, resourceIds[0]);

    const payload = generatePayloadFromValues(values, props.event);

    if (values.type === EventType.Event) {
      payload.locationName = firstResource?.locationName;
      payload.locationObj = firstResource
        ? {
            ...pick(firstResource.location, [
              'address',
              'address2',
              'city',
              'country',
              'state',
              'zipcode',
            ]),
            custom_data: { resourceId: firstResource.id },
          }
        : null;
    }

    const post = async (payload) => {
      const response = await postEventAPI(payload);
      dispatch(AddEvent(response.data));
      NotificationService.notifySuccess(
        gettextCatalog.getString('The event has been created')
      );
      props.closePopover();
    };

    try {
      await post(payload);
    } catch (error) {
      if (error?.status === 409) {
        props.closePopover();
        const result = await showConflicts(error.data);
        if (result) {
          await post({ ...payload, allowDoubleBooking: true });
        } else {
          props.closePopover();
        }
      } else {
        NotificationService.notifyError(
          gettextCatalog.getString(
            'An error occurred, please try again. If the problem persists, please contact our support.'
          )
        );
      }
    }
  };

  const onMoreOptions = () => {
    const values = form.getFieldsValue();
    if (currentValues.type === EventType.Event) {
      openEventDrawer({
        newEvent: {
          ...values,
          resources: extractResourceIds(values.resources).filter(
            // Remove type="church" resources before passing it to the event drawer
            (resourceId) =>
              !resources
                .filter((resource) => resource.type === 'church')
                .map((resource) => resource.id)
                .includes(resourceId)
          ),
          groups: values.groupIds?.map((group) => ({ id: group })) || [],
          churches: values.churchIds?.map((church) => ({ id: church })) || [],
          startDate: props.event.startDate,
          endDate: props.event.endDate,
          allDay: props.event.allDay,
          hideEndTime: true,
          fromPopup: true,
        },
      });
    } else {
      EventPopupService.editEventDetails(currentValues.type, {
        ...values,
        startDate: props.event.startDate,
        endDate: props.event.endDate,
        allDay: props.event.allDay,
        users: Number(values.users),
      });
    }
    props.closePopover();
  };

  // Automatically add the first church if the user selects a resource
  useEffect(() => {
    const churchIds = form.getFieldValue('churchIds');
    if (!churchIds || churchIds?.length === 0) {
      const resourceIds = extractResourceIds(currentValues?.resources);

      if (resourceIds.length > 0) {
        const resource = findResource(resources, resourceIds[0]);
        const resourceId = resource?.parentResourceId || resource?.id;
        if (
          cdApp.organization.churches
            .map((church) => church.id)
            .includes(resourceId)
        ) {
          form.setFieldValue('churchIds', [resourceId]);
          // Revalidate to remove error if user cleared the field but then re-adds a resource
          form.validateFields(['churchIds'], null);
        }
      }
    }
  }, [form, resources, currentValues?.resources]);

  const time = getFormattedDate({
    startDate: props.event.startDate,
    endDate: props.event.endDate,
    allDay: props.event.allDay,
  });

  const initialValues = {
    type: EventType.Event,
    visibility: getInitialEventVisibility(),
    startDate: props.event.startDate,
    endDate: props.event.endDate,
    allDay: props.event.allDay,
    resources: props.event.resources,
    users: props.event.users?.length > 0 ? props.event.users[0] : undefined,
  };

  return {
    form,
    type: currentValues?.type || initialValues.type, // Override on initial render to avoid jumping popover
    time,
    currentValues,
    onCreate,
    onMoreOptions,
    changeType,
    initialValues,
    selectedGroup,
    options: {
      groups: groupOptions || [],
      users,
      visibility: visibilityOptions,
      resources: resources || [],
    },
    showResourceSelector,
  };
};
