import React, { useEffect } from 'react';
import {
  Divider,
  Form,
  Input,
  FormInstance,
  Typography,
  DatePicker,
  Radio,
  Select,
  Spin,
} from 'antd';
import styled from 'styled-components';

import { ConflictingPeopleRelationComponent } from '../ConflictingPeopleRelationComponent';
import {
  ConflictingPersonWithRelation,
  ContactInformationType,
} from '../../services/people.service';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';

import { PhoneInput } from './PhoneInput';
import { NationalIdentificationNumberInput } from './NationalIdentificationNumberInput';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdCountrySelect } from '@/react/shared/components/cd-country-select/CdCountrySelect';
const CustomForm = styled(Form)`
.ant-divider-horizontal{
  margin-top:8px;
  margin-bottom:16px;
  }
`;
const FormItem = styled(Form.Item)`
  margin-bottom: 8px;
  `;
const ConfilictingPeoplePart = styled(Form.Item)`

`;
export const EditPersonalInformationForm = ({
  contactId,
  form,
  accessFields,
  personRelations,
  initialValues,
  isPending,
  isRefetching,
  setConflictingPeopleByEmail,
  setConflictingPeopleByPhone,
  conflictingPeopleByEmail,
  conflictingPeopleByPhone,
  civilStatusOptions,
  allergyOptions,
  dietaryOptions,
  denominationOptions,
}: {
  contactId: number;
  form: FormInstance;
  accessFields: any;
  personRelations: any;
  initialValues: any;
  isPending: boolean;
  isRefetching: boolean;
  setConflictingPeopleByEmail: (
    selectedValues: ConflictingPersonWithRelation[]
  ) => void;
  setConflictingPeopleByPhone: (
    selectedValues: ConflictingPersonWithRelation[]
  ) => void;
  conflictingPeopleByEmail: ConflictingPersonWithRelation[];
  conflictingPeopleByPhone: ConflictingPersonWithRelation[];
  civilStatusOptions: { label: string; value: string }[];
  allergyOptions: { label: string; value: string }[];
  dietaryOptions: { label: string; value: string }[];
  denominationOptions: { label: string; value: string }[];
}) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending, isRefetching, initialValues]);
  const labels = {
    email: gettextCatalog.getString('Email'),
    phone: gettextCatalog.getString('Mobile phone'),
    homePhone: gettextCatalog.getString('Home phone'),
    workPhone: gettextCatalog.getString('Work phone'),
    street: gettextCatalog.getString('Street address'),
    city: gettextCatalog.getString('City'),
    zipcode: gettextCatalog.getString('Postal code'),
    poBoxNumber: gettextCatalog.getString('PO. Box number'),
    poBoxPostCode: gettextCatalog.getString('PO. Box postal code'),
    country: gettextCatalog.getString('Country'),
    birthday: gettextCatalog.getString('Birthday'),
    baptismDate: gettextCatalog.getString('Baptism'),
    gender: gettextCatalog.getString('Gender'),
    occupation: gettextCatalog.getString('Occupation'),
    note: gettextCatalog.getString('Internal note'),
    civilStatus: gettextCatalog.getString('Civil status'),
    nationalIdentificationNumber: gettextCatalog.getString(
      'National identification number'
    ),
    allergy: gettextCatalog.getString('Allergies and intolerances'),
    dietary: gettextCatalog.getString('Diet'),
    medicalNote: gettextCatalog.getString('Medical note'),
    confirmationDate: gettextCatalog.getString('Confirmation'),
    civilMarriageDate: gettextCatalog.getString('Wedding'),
    membershipDate: gettextCatalog.getString('Date of parish membership'),
    denomination: gettextCatalog.getString('Denomination'),
  };
  const setConflictingPeopleByEmailSelectedValues = (
    selectedValues: ConflictingPersonWithRelation[]
  ): void => {
    setConflictingPeopleByEmail(selectedValues);
  };

  const setConflictingPeopleByPhoneSelectedValues = (
    selectedValues: ConflictingPersonWithRelation[]
  ): void => {
    setConflictingPeopleByPhone(selectedValues);
  };
  return (
    <Spin spinning={isPending}>
      <CustomForm
        form={form}
        colon={false}
        labelCol={{ span: 6 }}
        labelAlign="right"
      >
        <Typography.Title level={5}>
          {gettextCatalog.getString('Contact')}
        </Typography.Title>
        <Divider />
        {accessFields?.email?.canEdit ? (
          <CdVerticalSpace>
            <FormItem
              name="email"
              label={labels.email}
              rules={[{ type: 'email' }]}
            >
              <Input />
            </FormItem>
            <ConfilictingPeoplePart
              label={' '}
              shouldUpdate
              noStyle={conflictingPeopleByEmail.length === 0}
            >
              {({ getFieldValue }) => (
                <ConflictingPeopleRelationComponent
                  emailValue={getFieldValue('email')}
                  phoneValue={
                    getFieldValue('phone')?.phone &&
                    `+${
                      getFieldValue('phone').code + getFieldValue('phone').phone
                    }`
                  }
                  valueType={ContactInformationType.EMAIL}
                  shouldListSingleConflictingPeople={false}
                  omittingPersonId={contactId}
                  existingRelations={personRelations}
                  setSelectedValues={setConflictingPeopleByEmailSelectedValues}
                />
              )}
            </ConfilictingPeoplePart>
          </CdVerticalSpace>
        ) : (
          <AccessErrorMessage label={labels.email} />
        )}
        {accessFields?.phone?.canEdit ? (
          <CdVerticalSpace>
            <PhoneInput name="phone" label={labels.phone} />
            <Form.Item
              label=" "
              shouldUpdate
              noStyle={conflictingPeopleByPhone.length === 0}
            >
              {({ getFieldValue }) => (
                <ConflictingPeopleRelationComponent
                  emailValue={getFieldValue('email')}
                  phoneValue={
                    getFieldValue('phone')?.phone &&
                    `+${
                      getFieldValue('phone').code + getFieldValue('phone').phone
                    }`
                  }
                  valueType={ContactInformationType.PHONE}
                  shouldListSingleConflictingPeople={true}
                  omittingPersonId={contactId}
                  existingRelations={personRelations}
                  setSelectedValues={setConflictingPeopleByPhoneSelectedValues}
                />
              )}
            </Form.Item>
          </CdVerticalSpace>
        ) : (
          <AccessErrorMessage label={labels.phone} />
        )}
        {accessFields?.homePhone?.canEdit ? (
          <PhoneInput name="homePhone" label={labels.homePhone} />
        ) : (
          <AccessErrorMessage label={labels.homePhone} />
        )}
        {accessFields?.workPhone?.canEdit ? (
          <PhoneInput name="workPhone" label={labels.workPhone} />
        ) : (
          <AccessErrorMessage label={labels.workPhone} />
        )}
        <Typography.Title level={5}>
          {gettextCatalog.getString('Address')}
        </Typography.Title>
        <Divider />
        {accessFields?.street?.canEdit ? (
          <FormItem
            name="street"
            label={gettextCatalog.getString('Street address')}
          >
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.street} />
        )}
        {accessFields?.city?.canEdit ? (
          <FormItem name="city" label={gettextCatalog.getString('City')}>
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.city} />
        )}
        {accessFields?.zipcode?.canEdit ? (
          <FormItem
            name="zipcode"
            label={gettextCatalog.getString('Postal code')}
          >
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.zipcode} />
        )}
        {accessFields?.country.canEdit ? (
          <FormItem label={gettextCatalog.getString('Country')} name="country">
            <CdCountrySelect
              placeholder={gettextCatalog.getString('Country')}
              allowClear={false}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.country} />
        )}
        {accessFields?.poBoxNumber?.canEdit ? (
          <FormItem
            name="poBoxNumber"
            label={gettextCatalog.getString('P.O Box number')}
          >
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.poBoxNumber} />
        )}
        {accessFields?.poBoxPostCode?.canEdit ? (
          <FormItem
            name="poBoxPostCode"
            label={gettextCatalog.getString('P.O Box postal code')}
          >
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.poBoxPostCode} />
        )}

        <Typography.Title level={5}>
          {gettextCatalog.getString('Other Details')}
        </Typography.Title>
        <Divider />
        {accessFields?.gender?.canEdit ? (
          <FormItem name="gender" label={gettextCatalog.getString('Gender')}>
            <Radio.Group>
              <Radio value="male">{gettextCatalog.getString('Male')}</Radio>
              <Radio value="female">{gettextCatalog.getString('Female')}</Radio>
              <Radio value="other">{gettextCatalog.getString('Other')}</Radio>
            </Radio.Group>
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.gender} />
        )}
        {accessFields?.occupation?.canEdit ? (
          <FormItem
            name="occupation"
            label={gettextCatalog.getString('Occupation')}
          >
            <Input />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.occupation} />
        )}
        {accessFields?.note?.canEdit ? (
          <FormItem
            name="note"
            label={gettextCatalog.getString('Internal note')}
            style={{ marginBottom: '14px' }}
          >
            <Input.TextArea />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.note} />
        )}
        {accessFields?.civilStatus?.canEdit ? (
          <FormItem
            name="civilStatus"
            label={gettextCatalog.getString('Civil status')}
          >
            <Select options={civilStatusOptions} />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.civilStatus} />
        )}
        {accessFields?.denomination?.canEdit ? (
          <FormItem
            name="denomination"
            label={gettextCatalog.getString('Denomination')}
          >
            <Select options={denominationOptions} />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.denomination} />
        )}
        <Typography.Title level={5}>
          {gettextCatalog.getString('Life events')}
        </Typography.Title>
        <Divider />
        {accessFields?.birthday?.canEdit ? (
          <FormItem
            name="birthday"
            label={gettextCatalog.getString('Birthday')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.birthday} />
        )}
        {accessFields?.baptismDate?.canEdit ? (
          <FormItem
            name="baptismDate"
            label={gettextCatalog.getString('Date of baptism')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.baptismDate} />
        )}
        {accessFields?.confirmationDate?.canEdit ? (
          <FormItem
            name="confirmationDate"
            label={gettextCatalog.getString('Date of confirmation')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.confirmationDate} />
        )}
        {accessFields?.civilMarriageDate?.canEdit ? (
          <FormItem
            name="civilMarriageDate"
            label={gettextCatalog.getString('Date of wedding')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.civilMarriageDate} />
        )}
        {accessFields?.membershipDate?.canEdit ? (
          <FormItem
            name="membershipDate"
            label={gettextCatalog.getString('Date of parish membership')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.membershipDate} />
        )}
        <Typography.Title level={5}>
          {gettextCatalog.getString('Health')}
        </Typography.Title>
        <Divider />
        {accessFields?.nationalIdentificationNumber?.canEdit ? (
          <FormItem
            name="nationalIdentificationNumber"
            label={gettextCatalog.getString('National identification number')}
          >
            <NationalIdentificationNumberInput />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.nationalIdentificationNumber} />
        )}
        {accessFields?.allergy?.canEdit ? (
          <FormItem
            name="allergy"
            label={gettextCatalog.getString('Allergies and intolerances')}
          >
            <Select mode="multiple" options={allergyOptions} />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.allergy} />
        )}
        {accessFields?.dietary?.canEdit ? (
          <FormItem name="dietary" label={gettextCatalog.getString('Diet')}>
            <Select options={dietaryOptions} />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.dietary} />
        )}
        {accessFields?.medicalNote?.canEdit ? (
          <FormItem
            name="medicalNote"
            label={gettextCatalog.getString('Medical note')}
          >
            <Input.TextArea />
          </FormItem>
        ) : (
          <AccessErrorMessage label={labels.medicalNote} />
        )}
      </CustomForm>
    </Spin>
  );
};

const AccessErrorMessage = ({ label }) => (
  <FormItem label={label}>
    <i>
      {gettextCatalog.getString(
        'You do not have the permission to edit sensitive fields.'
      )}
    </i>
  </FormItem>
);
