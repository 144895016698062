import { Button, Divider, Form, Input, Row, Switch, Typography } from 'antd';
import React from 'react';

import { WeeklySchedule } from './components/weekly-schedule/WeeklySchedule';
import { useOptionFormData } from './hooks/useOptionFormData';

import gettextCatalog from '@/react/services/I18nService';
import { DurationPicker } from '@/react/settings/booking-pages/components/DurationPicker';
import { hasOverlappingTime } from '@/react/settings/booking-pages/validation';
import { required } from '@/react/shared/antd/validations/common';
import { CdLocationSelect } from '@/react/shared/components/cd-location-select/CdLocationSelect';
import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';

type OptionSettingsFormProps = {
  index: number;
  onDeleteBookingOption: (index: number) => void;
};

export const OptionForm = ({
  index,
  onDeleteBookingOption,
}: OptionSettingsFormProps) => {
  const { locations, allowWeeklySchedule, startIncrementOptions } =
    useOptionFormData(index);
  return (
    <>
      <Typography.Title level={3}>
        {gettextCatalog.getString('Booking Page')}
      </Typography.Title>
      <Form.Item
        name={['bookingOptions', index, 'name']}
        label={gettextCatalog.getString('Option name')}
        rules={[required()]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['bookingOptions', index, 'description']}
        label={gettextCatalog.getString('Description')}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item
        name={['bookingOptions', index, 'resourceId']}
        label={gettextCatalog.getString('Location')}
        rules={[required()]}
      >
        <CdLocationSelect
          selectedResources={locations}
          checkable={false}
          hideNoParishBookedOption
        />
      </Form.Item>
      <Form.Item
        name={['bookingOptions', index, 'additionalResourceIds']}
        label={gettextCatalog.getString('Book additional resources')}
      >
        <CdLocationSelect
          selectedResources={locations}
          hideNoParishBookedOption
        />
      </Form.Item>
      <Form.Item
        name={['bookingOptions', index, 'allowWeeklySchedule']}
        label={gettextCatalog.getString('Create weekly booking schedule')}
        extra={gettextCatalog.getString(
          'Define allowed booking enquiry time schedule. Example: Cemetery services are only possible between 9AM and 11AM. When not setting the schedule it means there is no limit on availability.'
        )}
        valuePropName="checked"
      >
        <Switch defaultChecked />
      </Form.Item>
      {allowWeeklySchedule && (
        <Form.Item
          name={['bookingOptions', index, 'bookingHours']}
          rules={[hasOverlappingTime]}
        >
          <WeeklySchedule />
        </Form.Item>
      )}
      <Typography.Title level={3}>
        {gettextCatalog.getString('Other')}
      </Typography.Title>
      <Form.Item
        name={['bookingOptions', index, 'duration']}
        label={gettextCatalog.getString('Event duration')}
      >
        <DurationPicker />
      </Form.Item>
      <Form.Item
        name={['bookingOptions', index, 'minimumNoticePeriod']}
        label={gettextCatalog.getString('Minimum Notice Period')}
      >
        <DurationPicker />
      </Form.Item>
      <CdVerticalSpace>
        <Typography.Title level={3}>
          {gettextCatalog.getString('Buffer')}
        </Typography.Title>
        <Typography.Text type="secondary">
          {gettextCatalog.getString(
            'Automatically blocks additional time around each enquiry to prevent back-to-back scheduling.'
          )}
        </Typography.Text>
        <Form.Item
          name={['bookingOptions', index, 'bufferBeforeEvent']}
          label={gettextCatalog.getString('Buffer before event')}
        >
          <DurationPicker />
        </Form.Item>
      </CdVerticalSpace>
      <Form.Item
        name={['bookingOptions', index, 'bufferAfterEvent']}
        label={gettextCatalog.getString('Buffer after event')}
      >
        <DurationPicker />
      </Form.Item>
      <Form.Item
        name={['bookingOptions', index, 'startTimeIncrement']}
        label={gettextCatalog.getString('Start time increment')}
      >
        <CdSelect
          aria-label="Start Time Increment"
          placeholder={gettextCatalog.getString(
            'Choose start time increment...'
          )}
          options={startIncrementOptions}
        />
      </Form.Item>
      <Divider />
      <Row justify="center">
        <Button danger type="text" onClick={() => onDeleteBookingOption(index)}>
          {gettextCatalog.getString('Delete Option')}
        </Button>
      </Row>
    </>
  );
};
