import { Form } from 'antd';
import { useCallback } from 'react';

import {
  useDuplicateContactRecord,
  useMergeDuplicateContact,
  useRejectDuplicateContactMerge,
} from '@/react/people/duplicate/store/duplicateContacts';

export const useDuplicateContact = (duplicateId: number) => {
  const { duplicateRecordData, isLoading } =
    useDuplicateContactRecord(duplicateId);
  const [duplicateContactMergeForm] = Form.useForm();
  const { rejectDuplicateContactMerge } = useRejectDuplicateContactMerge();

  const { mergeDuplicateContact } = useMergeDuplicateContact();
  const formInitialValue = {
    ...duplicateRecordData,
    selectedContact: duplicateRecordData?.contactOne.master
      ? duplicateRecordData?.contactOne.churchDeskId
      : duplicateRecordData?.contactTwo.churchDeskId,
    selectedEmail: duplicateRecordData?.contactOne.master
      ? duplicateRecordData?.contactOne.email
      : duplicateRecordData?.contactTwo.email,
    selectedPhoneNumber: duplicateRecordData?.contactOne.master
      ? duplicateRecordData?.contactOne.phoneNumber
      : duplicateRecordData?.contactTwo.phoneNumber,
  };
  const mergeHandler = useCallback(async () => {
    duplicateContactMergeForm.validateFields().then((values) => {
      mergeDuplicateContact({
        duplicateId: duplicateId,
        baseContactId: values.selectedContact,
        selectedEmail: values.selectedEmail,
        selectedPhoneNumber: values.selectedPhoneNumber,
      });
    });
  }, [duplicateContactMergeForm, duplicateId, mergeDuplicateContact]);

  return {
    duplicateContactMergeForm,
    duplicateRecordData,
    rejectDuplicateContactMerge,
    mergeDuplicateContact,
    formInitialValue,
    isLoading,
    mergeHandler,
  };
};
