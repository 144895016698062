import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import gettextCatalog from '@/react/services/I18nService';
import { useRejectDuplicateContactMerge } from '@/react/people/duplicate/store/duplicateContacts';

export const useDuplicateColumns = () => {
  const { rejectDuplicateContactMerge } = useRejectDuplicateContactMerge();
  const openRejectConfirmModal = (duplicateId: number) =>
    showConfirmModal({
      title: gettextCatalog.getString(
        'Are you sure you want to reject this record for merging'
      ),
      message: gettextCatalog.getString(
        'Rejecting this record means it will no longer be considered for merging. This action cannot be undone.',
        null,
        'Merge Duplicate Contacts'
      ),
      onOk: () => rejectDuplicateContactMerge(duplicateId),
      okText: gettextCatalog.getString('Reject'),
    });
  return {
    openRejectConfirmModal,
  };
};
