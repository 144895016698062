import { Form, Spin } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { DetailsForm } from './DetailsForm';
import { OptionForm } from './OptionForm';
import {
  MenuItem,
  VerticalTabMenu,
} from './components/vertical-tab-menu/VerticalTabMenu';
import { useAvailabilityFormData } from './hooks/useAvailabilityFormData';

import gettextCatalog from '@/react/services/I18nService';
import {
  ModalType,
  createCdModal,
} from '@/react/shared/components/cd-modal-new/CdModal2';

type BookingPageSettingsModalProps = {
  taxonomyId?: number;
};

export const showBookingPageSettingsModal =
  createCdModal<BookingPageSettingsModalProps>(
    ({ setModalProps, taxonomyId }) => {
      const {
        initialValues,
        form,
        onSave,
        onSaveAndContinue,
        isLoadingExistingTaxonomy,
        title,
        activeItem,
        setActiveItem,
        deleteBookingOption,
        currentBookingOptions,
        formData,
      } = useAvailabilityFormData(taxonomyId);

      useEffect(() => {
        setModalProps({
          title,
          modalType: ModalType.MODAL,
          alternativeButtonText: gettextCatalog.getString(
            'Save and continue editing'
          ),
          okText: gettextCatalog.getString('Save'),
          onOk: onSave,
          width: 850,
          handleAlternativeButtonClick: onSaveAndContinue,
        });
      }, [onSave, onSaveAndContinue, setModalProps, title]);

      const menuItems: MenuItem[] = useMemo(
        () => [
          {
            label: gettextCatalog.getString('Booking Page Details'),
            key: 'details',
          },
          {
            type: 'group',
            label: gettextCatalog.getString('Options'),
            children: [
              ...(currentBookingOptions ?? []).map((option, index) => ({
                key: 'option-' + index,
                label:
                  option.name || gettextCatalog.getString('Unnamed Option'),
              })),
              {
                key: 'add-option',
                label: '+ ' + gettextCatalog.getString('Add option'),
              },
            ],
          },
        ],
        [currentBookingOptions]
      );

      return (
        <VerticalTabMenu
          menuItems={menuItems}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        >
          {isLoadingExistingTaxonomy ? (
            <Spin />
          ) : (
            <Form form={form} layout="vertical" initialValues={initialValues}>
              <div
                // Making sure that all form fields are always mounted
                // because AntD validation works only on mounted fields
                style={{ display: activeItem === 'details' ? 'block' : 'none' }}
              >
                <DetailsForm formData={formData} />
              </div>
              {currentBookingOptions?.map((option, index) => (
                <div
                  style={{
                    display:
                      activeItem === 'option-' + index ? 'block' : 'none',
                  }}
                >
                  <OptionForm
                    key={index}
                    index={index}
                    onDeleteBookingOption={deleteBookingOption}
                  />
                </div>
              ))}
            </Form>
          )}
        </VerticalTabMenu>
      );
    }
  );
