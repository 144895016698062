import { useCallback } from 'react';

import { mainApi } from '../../../api';
import { handleError } from '../../../services/ErrorHandlingService';
import gettextCatalog from '../../../services/I18nService';
import NotificationService from '../../../services/NotificationService';
import { showConfirmModal } from '../../../shared/components/cd-confirm-modal/CdConfirmModal';
import { showEnquiryDrawer } from '../components/EnquiryDrawer';
import { Enquiry } from '../types/enquiry';

export const useEnquiriesPage = () => {
  const viewEnquiry = useCallback(
    (enquiry: Enquiry) => showEnquiryDrawer({ enquiry }),
    []
  );

  const approveEnquiry = useCallback(
    (enquiry: Enquiry) =>
      showConfirmModal({
        title: gettextCatalog.getString('Approve enquiry'),
        message: gettextCatalog.getString(
          'Please confirm that the enquiry is possible and that you have accepted. The enquirer will be informed accordingly.'
        ),
        onOk: async () => {
          const response = await mainApi.post(
            `/calendar/booking/enquiries/${enquiry.id}/approve`
          );
          if (response.ok) {
            NotificationService.notifySuccess(
              gettextCatalog.getString('Enquiry approved')
            );
          } else {
            handleError(response);
          }
        },
      }).then(({ resolved }) => {
        if (!resolved) return { preventClose: true };
      }),
    []
  );

  const declineEnquiry = useCallback(
    (enquiry: Enquiry) =>
      showConfirmModal({
        title: gettextCatalog.getString('Decline enquiry'),
        message: gettextCatalog.getString(
          'Please confirm that the enquiry is not possible. The enquirer will be informed accordingly.'
        ),
        onOk: async () => {
          const response = await mainApi.post(
            `/calendar/booking/enquiries/${enquiry.id}/decline`
          );
          if (response.ok) {
            NotificationService.notifySuccess(
              gettextCatalog.getString('Enquiry declined')
            );
          } else {
            handleError(response);
          }
        },
      }).then(({ resolved }) => {
        if (!resolved) return { preventClose: true };
      }),
    []
  );

  return {
    viewEnquiry,
    approveEnquiry,
    declineEnquiry,
  };
};
