import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createNewTaxonomy,
  getTaxonomy,
  updateTaxonomy,
} from '../services/taxonomy';

import { Taxonomy } from '@/react/shared/models/taxonomy';

export const useSaveNewTaxonomyMutation = () =>
  useMutation({
    mutationFn: (taxonomy: Partial<Taxonomy>) => createNewTaxonomy(taxonomy),
  });

export const useUpdateTaxonomyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (taxonomy: Partial<Taxonomy>) => updateTaxonomy(taxonomy),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['taxonomy'] }),
  });
};

export const useSingleTaxonomyQuery = (id?: number) =>
  useQuery({
    queryKey: ['taxonomy', id],
    queryFn: () => getTaxonomy(id!),
    enabled: !!id,
  });
