import React, { useState } from 'react';
import { Divider, Space, Typography } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import { Container, Header } from './people-contact/InformationCard';
import { ResubscribeToListsButton } from './ResubscribeAllPracticalEmailBasedInformationButton';

import CdrEmptyState from '@/react/shared/components/CdrEmptyState';
import {
  CdLock,
  CdCommentsIcon,
  CdListIcon,
  HEADER_BACKGROUND_COLOR,
  HEADER_BACKGROUND_BORDER_COLOR,
} from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';
import { CdCheckbox } from '@/react/shared/components/cd-checkbox/CdCheckbox';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { queryClient } from '@/react/shared/components/RootProvider';

const ListContainer = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
`;
export interface List {
  id: number;
  listName: string;
  listId: number;
  unsubscribedAt: string;
}
export const MessageSettings = ({
  contactId,
  smsNotification,
  emailNotification,
  canView,
  contactName,
  updateMessageSettings,
  communicateFromFilters,
  unSubscriptions,
  updateReceiveCommunicationFromFilters,
}: {
  smsNotification: boolean;
  emailNotification: boolean;
  canView: boolean;
  contactId: number;
  contactName: string;
  updateMessageSettings: (messageType: string, value: boolean) => void;
  communicateFromFilters: boolean;
  unSubscriptions: List[];
  updateReceiveCommunicationFromFilters: () => void;
}) => {
  const [smsCheckbox, setSmsCheckBox] = useState(smsNotification);
  const [emailCheckbox, setEmailCheckBox] = useState(emailNotification);
  const confirmMessageSetting = (messageType: string, value) => {
    const status: string = value
      ? gettextCatalog.getString('Enable')
      : gettextCatalog.getString('Disable');
    const messageTypeName: string =
      messageType === 'email'
        ? gettextCatalog.getString('E-mail')
        : gettextCatalog.getString('SMS');
    showConfirmModal({
      title: `${status} ${messageTypeName}`,
      message: gettextCatalog.getString(
        'Are you sure you want to {{status}} sending {{messageType}} to "{{contactName}}"?',
        {
          status: status.toLowerCase(),
          messageType: messageTypeName,
          contactName,
        },
        'Confirm message setting, status would be either enable or disable and messageType would be either email or sms'
      ),
      okText: status,
      okButtonProps: !value && { danger: true },
      cancelText: gettextCatalog.getString('Cancel'),
      onOk() {
        const type =
          messageType === 'email' ? 'emailNotification' : 'smsNotification';
        updateMessageSettings(type, value);
        queryClient.invalidateQueries({
          queryKey: ['usePeopleAnniveraryList'],
        });
      },
      onCancel() {
        if (messageType === 'email') {
          setEmailCheckBox(!value);
        } else {
          setSmsCheckBox(!value);
        }
      },
    });
  };
  const guiedContentText = () => {
    const text = gettextCatalog.getString(
      'Where should {{contactName}} receive messages?',
      {
        contactName: `@@@`,
      }
    );
    const [prefix, suffix] = text.split('@@@');
    return (
      <span>
        {prefix}
        <Typography.Text strong>{contactName}</Typography.Text>
        {suffix}
      </span>
    );
  };
  return (
    <Container>
      <Header>
        <Space>
          <CdCommentsIcon />
          {gettextCatalog.getString('Message settings')}
        </Space>
      </Header>
      <Divider style={{ marginTop: '12px', marginBottom: '10px' }} />
      {!canView && (
        <CdrEmptyState
          EmptyStateIcon={<CdLock />}
          subtitle={gettextCatalog.getString(
            'You do not have access to view this information'
          )}
          title={gettextCatalog.getString('Message settings hidden')}
        />
      )}
      {canView && (
        <CdVerticalSpace style={{ padding: '0px 16px' }}>
          {guiedContentText()}
          <CdCheckbox
            checked={emailCheckbox}
            onChange={(e) => {
              setEmailCheckBox(e.target.checked);
              confirmMessageSetting('email', e.target.checked);
            }}
          >
            {gettextCatalog.getString('E-mail')}
          </CdCheckbox>
          <CdCheckbox
            checked={smsCheckbox}
            onChange={(e) => {
              setSmsCheckBox(e.target.checked);
              confirmMessageSetting('sms', e.target.checked);
            }}
          >
            {gettextCatalog.getString('SMS')}
          </CdCheckbox>
          {(!communicateFromFilters || unSubscriptions.length > 0) && (
            <CdVerticalSpace>
              <div
                style={{
                  backgroundColor: HEADER_BACKGROUND_COLOR,
                  borderBottom: `1px solid ${HEADER_BACKGROUND_BORDER_COLOR}`,
                  padding: '16px',
                }}
              >
                <Space>
                  <CdListIcon />
                  <Typography.Text strong>
                    {gettextCatalog
                      .getString('Unsubscribed from list')
                      .toUpperCase()}
                  </Typography.Text>
                </Space>
              </div>
              {communicateFromFilters &&
                unSubscriptions.map((list) => (
                  <ListContainer key={list.id}>
                    <Typography.Text>{list.listName}</Typography.Text>
                    {moment(list.unsubscribedAt).format('lll')}
                    <ResubscribeToListsButton
                      personName={contactName}
                      communicateFromFilters={communicateFromFilters}
                      list={list}
                      contactId={contactId}
                    />
                  </ListContainer>
                ))}
              {!communicateFromFilters && (
                <ListContainer>
                  {gettextCatalog.getString(
                    'All practical email based information'
                  )}
                  <ResubscribeToListsButton
                    personName={contactName}
                    updateReceiveCommunicationFromFilters={
                      updateReceiveCommunicationFromFilters
                    }
                    communicateFromFilters={communicateFromFilters}
                  />
                </ListContainer>
              )}
            </CdVerticalSpace>
          )}
        </CdVerticalSpace>
      )}
    </Container>
  );
};
