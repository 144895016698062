import { Button, Card, Col, Form, Input, Row, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { gettextCatalog } from '../../../services/I18nService';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { CdSelect } from '../../../shared/components/cd-select/CdSelect';
import { EventType } from '../../models/calendar';
import { CdClose } from '../../../shared/components/Icons';
import { isInArray, required } from '../../../shared/antd/validations/common';
import { CdFormSubmitButton } from '../../../shared/components/cd-form-submit-button/CdFormSubmitButton';
import cdApp from '../../../config';
import { CdEventCategorySelect } from '../../event-details/components/CdEventCategorySelect';
import { CdLocationSelect } from '../../../shared/components/cd-location-select/CdLocationSelect';
import { CreateEventData } from '../../services/EventPopupService';
import { CdChurchParishSelect } from '../../../shared/components/selects/cd-church-parish-select/CdChurchParishSelect';
import { BookingOptionsSelect } from '../../availabilities/components/BookingOptionsSelect';

import { useCreateEventPopup } from './use-create-event-popup';

const { Text } = Typography;

export interface CreateEventPopupProps {
  event: CreateEventData;
  closePopover: () => void;
}

export const CreateEventPopover = (props: CreateEventPopupProps) => {
  const {
    form,
    time,
    type,
    options,
    selectedGroup,
    currentValues,
    onCreate,
    onMoreOptions,
    changeType,
    initialValues,
    showResourceSelector,
  } = useCreateEventPopup(props);

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      colon={false}
      initialValues={initialValues}
    >
      <StyledCard
        bordered={false}
        title={
          <Row justify="space-between">
            <Col>
              <Form.Item name="type" noStyle>
                <Space>
                  <Button
                    onClick={() => changeType(EventType.Event)}
                    ghost={type !== EventType.Event}
                  >
                    {gettextCatalog.getString('Event')}
                  </Button>
                  <Button
                    onClick={() => changeType(EventType.Absence)}
                    ghost={type !== EventType.Absence}
                  >
                    {gettextCatalog.getString('Absence')}
                  </Button>
                  <Button
                    onClick={() => changeType(EventType.Availability)}
                    ghost={type !== EventType.Availability}
                  >
                    {gettextCatalog.getString('Availability')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
            <Col>
              <IconButton
                type="text"
                shape="circle"
                icon={<CdClose type="white" />}
                color="white"
                onClick={props.closePopover}
              />
            </Col>
          </Row>
        }
      >
        <Row justify="end" style={{ marginBottom: 16 }}>
          <Col span={18}>
            <CdVerticalSpace size={0}>
              <Text strong>{time.title}</Text>
              {time.subtitle && <Text type="secondary">{time.subtitle}</Text>}
            </CdVerticalSpace>
          </Col>
        </Row>
        {type === EventType.Event && (
          <StyledFormItem
            name="title"
            label={gettextCatalog.getString('Title')}
            rules={[required()]}
          >
            <Input
              placeholder={gettextCatalog.getString('Enter a title.')}
              autoFocus
            />
          </StyledFormItem>
        )}
        {(type === EventType.Absence || type === EventType.Availability) && (
          <StyledFormItem
            name="users"
            label={gettextCatalog.getString('User')}
            dependencies={['groupId']}
            rules={[
              required(),
              isInArray(
                selectedGroup?.members,
                gettextCatalog.getString('This user is not part of the group')
              ),
            ]}
          >
            <CdSelect
              options={options.users}
              placeholder={gettextCatalog.getString('Choose a user')}
              allowClear={false}
            />
          </StyledFormItem>
        )}
        <StyledFormItem
          // Key required to avoid weird dropdown scrolling behavior
          // See https://app.shortcut.com/churchdesk/story/33189/use-react-selects-in-quick-calendar-entry-create
          key={`${type}-category`}
          name="mainCategory"
          label={
            type === EventType.Availability
              ? gettextCatalog.getString('Type')
              : gettextCatalog.getString('Category')
          }
          rules={[required()]}
        >
          <CdEventCategorySelect
            placeholder={gettextCatalog.getString('Choose a category')}
            type={type}
          />
        </StyledFormItem>
        {type === EventType.Event && showResourceSelector && (
          <StyledFormItem
            name="resources"
            label={gettextCatalog.getString('Resource')}
          >
            <CdLocationSelect
              selectedResources={options.resources}
              placeholder={gettextCatalog.getString(
                'Select resources for the event'
              )}
              hideNoParishBookedOption
              showSearch={true}
              maxTagCount="responsive"
            />
          </StyledFormItem>
        )}
        {type === EventType.Event &&
          cdApp.showChurchSelector &&
          currentValues?.visibility !== 'private' && (
            <StyledFormItem
              name="churchIds"
              label={gettextCatalog.getString('Parish')}
              rules={[required()]}
            >
              <CdChurchParishSelect />
            </StyledFormItem>
          )}
        {type === EventType.Event && (
          <StyledFormItem
            name="visibility"
            label={gettextCatalog.getString('Visibility')}
          >
            <CdSelect
              placeholder={gettextCatalog.getString('Choose a visibility')}
              options={options.visibility}
              allowClear={false}
            />
          </StyledFormItem>
        )}
        {(type === EventType.Absence ||
          currentValues?.visibility === 'internal-group' ||
          currentValues?.visibility === 'public') && (
          <StyledFormItem
            name={type === EventType.Absence ? 'groupId' : 'groupIds'}
            label={gettextCatalog.getString('Group')}
          >
            <CdSelect
              options={options.groups}
              mode={type === EventType.Absence ? null : 'multiple'}
              maxTagCount="responsive"
              placeholder={gettextCatalog.getString('Choose a group')}
            />
          </StyledFormItem>
        )}
        {type === EventType.Availability && (
          <StyledFormItem
            name="bookingOptionIds"
            label={gettextCatalog.getString('Options')}
            rules={[required()]}
          >
            <BookingOptionsSelect taxonomyId={currentValues?.mainCategory} />
          </StyledFormItem>
        )}
        <Row justify="end" style={{ marginTop: 16 }}>
          <Col>
            <Space>
              <CdFormSubmitButton form={form} onClick={onCreate}>
                {type === EventType.Event
                  ? gettextCatalog.getString('Create event')
                  : type === EventType.Absence
                    ? gettextCatalog.getString('Create absence')
                    : gettextCatalog.getString('Create availability')}
              </CdFormSubmitButton>
              {type !== EventType.Availability && (
                <Button type="link" onClick={onMoreOptions}>
                  {gettextCatalog.getString('More options')}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </StyledCard>
    </Form>
  );
};

const StyledCard = styled(Card)`
  width: 450px;

  .ant-card-head {
    padding: 16px;
    background-color: #7ab800;
  }

  .ant-card-body {
    padding: 16px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
