import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {
  AddressInterface,
  safeguardingLevelChecks,
  safeguardingRoles,
} from '../types/SafeguardingTypes';
import { CdUserAvatar } from '../../user/components/cd-user-avatar/CdUserAvatar';
import { gettextCatalog } from '../../services/I18nService';
import { useSafeguardingForm } from '../hooks/useSafeguardingForm';

import { FileDisplayTable } from './FileDisplayTable';
import { FileDisplayTableColumns } from './FileDisplayTableColumn';
import { showUserAddressModal } from './UserAddressModal';

import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import {
  getSafeguardingLevelText,
  safeguardingFormSelect,
} from '@/react/safeguarding/common/Helpers';
import {
  CdCircleCheck,
  CdEditIcon,
  CdPlus,
} from '@/react/shared/components/Icons';
import { organizationStatus } from '@/react/user/screens/UserList';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';
import { formatAddress } from '@/react/shared/utils';

const StyledCard = styled(Card)`
  &&&&.ant-card-bordered {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const SafeguardingForm = ({
  isEdit,
  currentUserId,
  currentUserOrganizationStatus,
  closeDrawer,
}: {
  isEdit?: boolean;
  currentUserId: number;
  currentUserOrganizationStatus: string;
  closeDrawer: () => void;
}) => {
  const {
    safeguardingForm,
    safeguardingData,
    onSafeguardingInfoSaveHandler,
    deleteFile,
  } = useSafeguardingForm(currentUserId);

  const sortedGroups = [...safeguardingData?.groups].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const sortedChurches = [...safeguardingData?.churches].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const fileDisplayTableRowDeleteHandler = (id: number) =>
    showConfirmModal({
      title: gettextCatalog.getString(
        'Are you sure you want to delete this record?'
      ),
      message: (
        <Typography.Paragraph>
          {gettextCatalog.getString('The record will be deleted permanently.')}
        </Typography.Paragraph>
      ),
      onOk: () => deleteFile(id),
    });

  const onAddressChange = (address: AddressInterface) => {
    safeguardingForm.setFieldsValue({
      address: address,
    });
    return onSafeguardingInfoSaveHandler();
  };

  const showAddress = (address: AddressInterface) => {
    if (address) {
      return (
        <CdVerticalSpace>
          {formatAddress(address)}
          {currentUserOrganizationStatus ===
            organizationStatus.CREATED_WITHOUT_LOGIN && (
            <Button
              style={{ paddingLeft: '0px' }}
              type="link"
              icon={<CdEditIcon />}
              disabled={!isEdit}
              onClick={() =>
                showUserAddressModal({
                  userFullName:
                    safeguardingData.fullName || safeguardingData.email,
                  address: address,
                  onAddressSaveHandler: (value) => onAddressChange(value),
                })
              }
            >
              {gettextCatalog.getString('Edit user address')}
            </Button>
          )}
        </CdVerticalSpace>
      );
    } else {
      return (
        <Button
          style={{ paddingLeft: '0px' }}
          type="link"
          icon={<CdPlus />}
          disabled={!isEdit}
          onClick={() =>
            showUserAddressModal({
              userFullName: safeguardingData.fullName || safeguardingData.email,
              address: address,
              onAddressSaveHandler: (value) => onAddressChange(value),
            })
          }
        >
          {gettextCatalog.getString('Add user address')}
        </Button>
      );
    }
  };

  return (
    <Form
      form={safeguardingForm}
      initialValues={safeguardingData}
      layout="vertical"
    >
      <Row>
        <StyledCard>
          <Col span={24}>
            <Space direction="horizontal">
              <CdUserAvatar picture={safeguardingData?.pictureUri} size={80} />
              <CdVerticalSpace style={{ marginLeft: '24px' }} size={2}>
                <Typography.Title level={2}>
                  {safeguardingData?.fullName}
                </Typography.Title>
                <Typography.Text>{safeguardingData?.email}</Typography.Text>
                <Typography.Text>
                  {safeguardingData?.birthdayDate &&
                    moment(safeguardingData?.birthdayDate).format('LL')}
                </Typography.Text>

                <Form.Item noStyle name="address">
                  {showAddress(safeguardingData?.address)}
                </Form.Item>
                {sortedGroups.length > 0 && (
                  <Space
                    direction="horizontal"
                    size={4}
                    style={{ flexWrap: 'wrap', alignItems: 'center' }}
                  >
                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                      {gettextCatalog.getString('In groups')}:
                    </Typography.Text>
                    <Space
                      direction="horizontal"
                      size={4}
                      style={{ flexWrap: 'wrap' }}
                    >
                      {sortedGroups.slice(0, 3).map((group) => (
                        <Tag
                          key={group.id}
                          style={{
                            maxWidth: '150px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {group.name}
                        </Tag>
                      ))}
                      {sortedGroups.length > 3 && (
                        <Popover
                          title={gettextCatalog.getString('Groups')}
                          placement="right"
                          trigger="click"
                          content={
                            <div
                              style={{ maxHeight: '500px', overflowY: 'auto' }}
                            >
                              <CdVerticalSpace
                                style={{
                                  maxHeight: '200px',
                                  overflowY: 'auto',
                                }}
                              >
                                {sortedGroups.map((group) => (
                                  <Typography.Text key={group.id}>
                                    {group.name}
                                  </Typography.Text>
                                ))}
                              </CdVerticalSpace>
                            </div>
                          }
                        >
                          <Button type="link" style={{ marginBottom: '4px' }}>
                            {gettextCatalog.getString('See all')}
                          </Button>
                        </Popover>
                      )}
                    </Space>
                  </Space>
                )}

                {sortedChurches.length > 0 && (
                  <Space
                    direction="horizontal"
                    size={4}
                    style={{ flexWrap: 'wrap', alignItems: 'center' }}
                  >
                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                      {gettextCatalog.getString('In churches')}:
                    </Typography.Text>
                    <Space
                      direction="horizontal"
                      size={4}
                      style={{ flexWrap: 'wrap' }}
                    >
                      {sortedChurches.slice(0, 3).map((church) => (
                        <Tag
                          key={church.id}
                          style={{
                            maxWidth: '150px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {church.name}
                        </Tag>
                      ))}
                      {sortedChurches.length > 3 && (
                        <Popover
                          title={gettextCatalog.getString('Churches')}
                          placement="right"
                          trigger="click"
                          content={
                            <div
                              style={{ maxHeight: '500px', overflowY: 'auto' }}
                            >
                              <CdVerticalSpace
                                style={{
                                  maxHeight: '200px',
                                  overflowY: 'auto',
                                }}
                              >
                                {sortedChurches.map((church) => (
                                  <Typography.Text key={church.id}>
                                    {church.name}
                                  </Typography.Text>
                                ))}
                              </CdVerticalSpace>
                            </div>
                          }
                        >
                          <Button type="link" style={{ marginBottom: '4px' }}>
                            {gettextCatalog.getString('See all')}
                          </Button>
                        </Popover>
                      )}
                    </Space>
                  </Space>
                )}

                {safeguardingData?.highestValidatedLevelInChurchDesk && (
                  <Space direction="horizontal">
                    <Typography.Text>
                      {gettextCatalog.getString(
                        'Verified by third party: {{level}}',
                        {
                          level: getSafeguardingLevelText(
                            safeguardingData?.highestValidatedLevelInChurchDesk
                          ),
                        }
                      )}
                    </Typography.Text>
                    <CdCircleCheck type="success" />
                  </Space>
                )}
              </CdVerticalSpace>
            </Space>
          </Col>
        </StyledCard>
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {gettextCatalog.getString('Safeguarding information')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item
              name={['safeguardInfo', 'type']}
              label={gettextCatalog.getString('Work')}
              extra={gettextCatalog.getString('Select the type of employment')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                disabled={!isEdit}
                options={safeguardingRoles().map((role) => ({
                  label: role.label,
                  value: role.value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={['safeguardInfo', 'workAndResponsibilities']}
              label={gettextCatalog.getString('Work field & responsibilities')}
              extra={gettextCatalog.getString(
                'Describe the work and responsibilities'
              )}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Input disabled={!isEdit} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name={['safeguardInfo', 'safeguardRequirement']}
              label={gettextCatalog.getString('Safeguarding measures required')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                disabled={!isEdit}
                options={safeguardingLevelChecks().map(safeguardingFormSelect)}
              />
            </Form.Item>
          </Col>
          {isEdit && (
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Space size="small">
                <Button type="default" onClick={closeDrawer}>
                  {gettextCatalog.getString('Close')}
                </Button>
                <Button type="primary" onClick={onSafeguardingInfoSaveHandler}>
                  {gettextCatalog.getString('Save')}
                </Button>
              </Space>
            </Col>
          )}
        </StyledCard>
        {/* Documents card */}
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {gettextCatalog.getString('Documents')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="documents">
              <FileDisplayTable
                currentUserId={currentUserId}
                fileType="document"
                tableColumns={FileDisplayTableColumns(
                  'document',
                  isEdit,
                  fileDisplayTableRowDeleteHandler
                )}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>
        </StyledCard>
        {/* Courses card */}
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {gettextCatalog.getString('Courses')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="courses">
              <FileDisplayTable
                currentUserId={currentUserId}
                fileType="course"
                tableColumns={FileDisplayTableColumns(
                  'course',
                  isEdit,
                  fileDisplayTableRowDeleteHandler
                )}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>
        </StyledCard>
        {/* Certificate of conduct card */}
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2}>
              {gettextCatalog.getString('Certificate of Conduct')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="certificatesOfConduct">
              <FileDisplayTable
                currentUserId={currentUserId}
                fileType="certificateOfConduct"
                tableColumns={FileDisplayTableColumns(
                  'certificateOfConduct',
                  isEdit,
                  fileDisplayTableRowDeleteHandler
                )}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>
        </StyledCard>
      </Row>
    </Form>
  );
};
