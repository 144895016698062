import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

import { FormBookingHours } from '../../types/availability-taxonomy.types';

import { Weekdays } from '@/react/shared/models/taxonomy';

export const useWeeklyScheduleEvents = (
  value: FormBookingHours,
  onChange?: (value: FormBookingHours) => void
) => {
  const [expandedWeekdays, setExpandedWeekdays] = useState<string[]>(
    Object.entries(value)
      .filter(([, v]) => v.enabled)
      .map(([k]) => k)
  );

  const onWeekdayEnabledChange = (weekDay: Weekdays) => () => {
    const currentData = value[weekDay];
    onChange({
      ...value,
      [weekDay]: {
        ...value[weekDay],
        enabled: !currentData.enabled,
      },
    });
    if (currentData.enabled) {
      setExpandedWeekdays(expandedWeekdays.filter((d) => d !== weekDay));
    } else {
      setExpandedWeekdays([...expandedWeekdays, weekDay]);
    }
  };

  const onTimeRangeChange =
    (weekDay: Weekdays, item: FormBookingHours[Weekdays]['data'][number]) =>
    (values: [Dayjs, Dayjs]) => {
      const [start, end] = values;
      onChange({
        ...value,
        [weekDay]: {
          ...value[weekDay],
          data: value[weekDay].data.map((d) =>
            d === item ? { start: dayjs(start), end: dayjs(end) } : d
          ),
        },
      });
    };

  const onTimeRangeRemove =
    (weekDay: Weekdays, item: FormBookingHours[Weekdays]['data'][number]) =>
    () => {
      onChange({
        ...value,
        [weekDay]: {
          ...value[weekDay],
          data: value[weekDay].data.filter((d) => d !== item),
        },
      });
    };

  const onTimeRangeAdd = (weekDay: Weekdays) => () => {
    onChange({
      ...value,
      [weekDay]: {
        ...value[weekDay],
        data: [
          ...value[weekDay].data,
          {
            start: '',
            end: '',
          },
        ],
      },
    });
  };

  return {
    expandedWeekdays,
    setExpandedWeekdays,
    onWeekdayEnabledChange,
    onTimeRangeChange,
    onTimeRangeRemove,
    onTimeRangeAdd,
  };
};
